import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import StatusHelper from "../../helpers/StatusHelper";
import Money from "../Money";
import OrderSalesLineGraph from "./OrderSalesLineGraph";
import DateHelper from "../../helpers/DateHelper";
import OrderIconSmall from "../icons/OrderIconSmall";

export const DashboardOrderHistory = (props) => {
  const tdMoneyAlign = props.tdMoneyAlign || "left";
  const tdDateAlign = tdMoneyAlign == "left" ? "left" : "middle";
  const tdStatusAlign = tdMoneyAlign == "left" ? "left" : "right";

  const orderData = props.orderData || {};
  const monthlyTotals = props.monthlyTotals || [];
  const currency = props.currency || "USD";
  const country = props.country || "US";
  const currencySymbol = props.currencySymbol || "$";
  const history = useHistory();

  const handleRowClick = (order) => {
    if (!order) {
      console.error("Order data is missing for row click.");
      return;
    }

    history.push(
      `/order/${order.orderNumber}/${order.orderCompany}/${order.orderType}`
    );
  };

  return (
    <>
      {props.storeSettings.settingsList.UseDashboardOrderHistory === "1" && (
        <div className="order-panel">
          <div className="title-flex-container">
            <div>
              <div>
                <OrderIconSmall />
              </div>

              <div className="title-label">
                {props.dashboardLabels.ORDERHISTORY}
              </div>
            </div>

            <div
              className={
                props.isFueling ? "expand-wrapper-ffs" : "expand-wrapper"
              }
            >
              <Link
                className="link-style"
                to={{
                  pathname: "/orders",
                  query: { statuses: [], dateRange: 729 },
                }}
              >
                <button className="expand-button">
                  <text className="expand-button-text">
                    {props.dashboardLabels.ViewAll}
                  </text>
                </button>
              </Link>
            </div>
          </div>

          <div className="data-flex-container">
            {orderData &&
            orderData.salesOrdersResponse &&
            orderData.salesOrdersResponse.length > 0 ? (
              <table className="table-style">
                <th className="th-left">{props.dashboardLabels.ORDERNUMBER}</th>

                <th className="th-middle-right">
                  {props.dashboardLabels.PONUMBER}
                </th>

                <th className="th-middle-right">
                  {props.dashboardLabels.Total}
                </th>

                <th className="th-order-date">
                  {props.dashboardLabels.OrderDate}
                </th>

                <th className="th-middle-right">
                  {props.dashboardLabels.Status}
                </th>

                {orderData.salesOrdersResponse.slice(0, 10).map((order) => {
                  return (
                    <tr className="row-wrapper" key={order.orderNumber}>
                      <td
                        onClick={() => handleRowClick(order)}
                        className="td-left-link"
                      >
                        {order.orderNumber}
                      </td>

                      <td className="td-po">{order.poNumber}</td>

                      <td className={`td-${tdMoneyAlign}`}>
                        <Money
                          country={country}
                          currency={order.priceCurrency}
                          value={order.total}
                        />
                      </td>

                      <td className={`td-${tdDateAlign}`}>
                        {DateHelper.format(order.orderDate, props.dateFormat)}
                      </td>

                      <td className={`td-${tdStatusAlign}`}>
                        {StatusHelper.formatStatus(
                          order.statusDescription,
                          props.dashboardLabels
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            ) : (
              <div>{props.dashboardLabels.NoOrders}</div>
            )}
          </div>

          {orderData &&
            orderData.salesOrdersResponse &&
            orderData.salesOrdersResponse.length > 0 && (
              <OrderSalesLineGraph
                monthlyTotals={monthlyTotals}
                country={country}
                dashboardLabels={props.dashboardLabels || {}}
                currencySymbol={currencySymbol}
                currency={currency}
              />
            )}
        </div>
      )}
    </>
  );
};

DashboardOrderHistory.propTypes = {
  orderData: PropTypes.object,
  monthlyTotals: PropTypes.array,
  currency: PropTypes.string,
  country: PropTypes.string,
  currencySymbol: PropTypes.string,
};

DashboardOrderHistory.defaultProps = {
  orderData: { salesOrdersResponse: [] },
  monthlyTotals: [],
  currency: "USD",
  country: "US",
  currencySymbol: "$",
  tdMoneyAlign: "left",
  dateFormat: "MM/DD/YYYY",
  dashboardLabels: {},
  storeSettings: { settingsList: {} },
  isFueling: false,
};

function mapStateToProps(state) {
  return {
    selectedCustomer: state.user.selectedCustomer,
  };
}

export default connect(mapStateToProps, null)(DashboardOrderHistory);
