import React, { useState, useEffect } from 'react';
import DateDisplay from '../DateDisplay';
import Loading from '../Loading';
import Api from '../../services/Api';
import StatusHelper from '../../helpers/StatusHelper';
import { CSVLink } from "../../modules/react-csv";
import DateHelper from "../../helpers/DateHelper";
import Money from '../Money';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import Notifications from '../../services/Notifications';
import PDFIcon from '../icons/OrderAckIcon';
import WhatsAppIcon from '../icons/WhatsAppIcon';
import PricingHelper from '../../helpers/PricingHelper';
import { Link } from 'react-router-dom';

const OrderLines = (props) => {
    const salesHistoryConfig = useSalesHistoryConfig();
    const localized = salesHistoryConfig.labels;
    const excludeStatuses = [
        'Shipped',
        'Complete',
        'Cancelled',
        'PartiallyShipped',
        'ReadyToInvoice',
        'Invoiced'
    ];
    const [csvData, setCSVData] = useState([]);
    const [fileExistenceData, setFileExistenceData] = useState([]);
    const [isFileCheckComplete, setIsFileCheckComplete] = useState(false);

    let orderLine = props.orderLines;

    useEffect(() => {
        if (props.csvOrders) {
            const formattedData = formatCSVData(props.csvOrders);
            setCSVData(formattedData);
        }

        if (props.orderLines) {
            const orders = mapOrderLinesToOrders(props.orderLines);
            checkOrderFileExists(orders);
        }
    }, [props.csvOrders, props.orderLines]);

    const formatCSVData = (data) => {
        const formattedData = JSON.parse(JSON.stringify(data));
        formattedData.forEach((item) => {
            item.unitPrice_Amount = PricingHelper.formatNumber(props.selectedCustomer.address.country, item.unitPrice_Amount);
            item.price_Amount = PricingHelper.formatNumber(props.selectedCustomer.address.country, item.price_Amount);
            item.price_Currency = undefined;
            if (item.orderDate) {
                item.orderDate = DateHelper.format(item.orderDate, "yyyy-MMM-dd");
            }
            if (item.requestDate) {
                item.requestDate = DateHelper.format(item.requestDate, "yyyy-MMM-dd");
            }
            if (item.estimatedDate) {
                item.estimatedDate = DateHelper.format(item.estimatedDate, "yyyy-MMM-dd");
            }
            if (item.estimatedShipDate) {
                item.estimatedShipDate = DateHelper.format(item.estimatedShipDate, "yyyy-MMM-dd");
            }
            if (item.lineStatus) {
                item.lineStatus = StatusHelper.formatStatus(item.lineStatus, props.localized);
            }
            if (item.productDescription) {
                item.productDescription = item.productDescription.replace('"', '');
            }
        });
        return formattedData;
    };

    const getPDF = (orderNumber, orderType, billToId) => {
        Api.downloadFileName(`/api/orders/GetPDF/${billToId}/${orderNumber}/${orderType}`, `${billToId}-${orderNumber}${orderType}`)
            .then(handleError);
    };

    const getPDFLink = (orderNumber, orderType, billToId) => {
        Api.fetch(`/api/orders/GetPDFShareLink/${billToId}/${orderNumber}/${orderType}`, `${billToId}-${orderNumber}${orderType}`)
            .then((res) => {
                window.open(res.shareUrl, '_blank');
            });
    };

    const handleError = (res) => {
        if (!res)
            Notifications.error(props.localized.NoPDFMessage);
    };

    const getCSVHeaders = () => {
        return [
            { label: props.localized.OrderNumber, key: "orderId" },
            { label: props.localized.PONumber, key: "referenceNumber" },
            { label: props.localized.DateOrdered, key: "orderDate" },
            { label: props.localized.RequestedDate, key: "requestDate" },
            { label: props.localized.ShipDate, key: "estimatedDate" },
            { label: props.localized.ItemNumber, key: "productSku" },
            { label: props.localized.ItemDescription, key: "productDescription" },
            { label: props.localized.QtyLabel, key: "quantity" },
            { label: props.localized.QtyBackOrderedLabel, key: "quantityBackordered" },
            { label: props.localized.UnitPrice, key: "unitPrice_Amount" },
            { label: props.localized.ProductTotal, key: "price_Amount" },
            { label: props.localized.Status, key: "lineStatus" },
            { label: props.localized.InvoiceNumber, key: "invoiceNumber" }
        ];
    };

    const mapOrderLinesToOrders = (orderLines) => {
        const orders = orderLines.map(orderLine => ({
            OrderNumber: orderLine.orderId,
            OrderType: orderLine.orderType,
            BillToId: orderLine.billToId,
            FileName: `${orderLine.billToId}-${orderLine.orderId}${orderLine.orderType}.pdf`
        }));
        return { Orders: orders };
    };

    const checkOrderFileExists = async (mappedOrders) => {
        if (mappedOrders.Orders.length > 0) {
            const response = await Api.checkOrderFileExists(`/api/orders/checkOrderFileExists`, mappedOrders);
            if (response.ok) {
                const data = await response.json();
                setFileExistenceData(data);
                setIsFileCheckComplete(true);
            }
        }
        else {
            setIsFileCheckComplete(true);
        }
    };

    const renderOrderLine = (orderLine, index, localized, selectedCustomer, ordersByLinePDF, shareViaWhatsApp, showEstimatedShipDate) => {
        if (isFileCheckComplete) {
            const fileData = fileExistenceData.find(file => String(file.orderNumber).trim() == String(orderLine.orderId).trim() && file.exists == true);
            
            const showIcons = fileData ? fileData.exists : false;


            return (

                <div key={index} className="br-details">
                    <div className="br-order-number">

                        <div className="label">{localized.OrderNumber}</div>
                        <Link className="fele-content" to={`/order/${orderLine.orderId}/${orderLine.orderCompany}/${orderLine.orderType}`}>{orderLine.orderId}</Link>
                    </div>

                    <div className="br-po-number">
                        <div className="label">{localized.PONumber}</div>
                        <div className="fele-content">{orderLine.referenceNumber}</div>
                    </div>

                    <div className="br-date">
                        <div className="label">{localized.DateOrdered}</div>
                        <div className="fele-content">
                            <DateDisplay date={orderLine.orderDate} dateFormat={props.dateDisplayFormat} />
                        </div>
                    </div>

                    <div className="br-requested-date">
                        <div className="label">{localized.RequestedDate}</div>
                        <div className="fele-content">
                            <DateDisplay date={orderLine.requestDate} dateFormat={props.dateDisplayFormat} />
                        </div>
                    </div>
                    {
                        props.showEstimatedShipDate &&
                        <div className="br-requested-date">
                            <div className="label">{localized.EstimatedShipDateOrderH}</div>
                            <div className="fele-content">
                                {(!excludeStatuses.includes(orderLine.lineStatus))
                                    ? <>
                                        {(DateHelper.compareDates(orderLine.estimatedDate, orderLine.orderDate) === -1)
                                            ? <div className="fele-content">{"NA"}</div>
                                            : <DateDisplay date={orderLine.estimatedDate} dateFormat={props.dateDisplayFormat} />
                                        }
                                    </>
                                    : <>
                                        {(DateHelper.compareDates(orderLine.shipDate, orderLine.orderDate) === -1)
                                            ? <div className="fele-content">{"NA"}</div>
                                            : <DateDisplay date={orderLine.shipDate} dateFormat={props.dateDisplayFormat} />
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    }
                    <div className="br-item-number">
                        <div className="label">{localized.ItemNumber}</div>
                        <div className="fele-content">{orderLine.productSku}</div>
                    </div>

                    <div className="br-item-description">
                        <div className="label">{localized.ItemDescription}</div>
                        <div className="fele-content">{orderLine.lineDescription}</div>
                    </div>

                    <div className="br-item-quantity">
                        <div className="label">{localized.ItemQuantity}</div>
                        <div className="fele-content">{orderLine.quantity}</div>
                    </div>

                    <div className="br-item-quantity-backordered">
                        <div className="label">{localized.ItemQuantityBackordered}</div>
                        <div className="fele-content">{orderLine.quantityBackordered}</div>
                    </div>

                    <div className="br-unit-price">
                        <div className="label">{localized.UnitPrice}</div>
                        <div className="fele-content"><Money value={orderLine.unitPrice_Amount} currency={orderLine.price_Currency} country={selectedCustomer.address.country} /></div>
                    </div>

                    <div className="br-product-total">
                        <div className="label">{localized.ProductTotal}</div>
                        <div className="fele-content"><Money value={orderLine.price_Amount} currency={orderLine.price_Currency} country={selectedCustomer.address.country} /></div>
                    </div>

                    <div className="br-line-status">
                        <div className="label">{localized.LineStatus}</div>
                        <div className="fele-content">{StatusHelper.formatStatus(orderLine.lineStatus, localized)}</div>
                    </div>

                    <div className="br-invoice-number">
                        <div className="label">{localized.InvoiceNumber}</div>
                        <div className="fele-content">{orderLine.invoiceNumber}</div>
                    </div>

                    <div className="br-invoice-pdf d-inline-flex" style={{ fontSize: '18px' }}>
                        {ordersByLinePDF && (
                            <>
                                <div
                                    style={{
                                        cursor: showIcons ? 'pointer' : 'not-allowed',
                                        paddingRight: '14px',
                                        opacity: showIcons ? 1 : 0.5,
                                    }}
                                    aria-disabled={!showIcons}
                                    onClick={() => {
                                        if (showIcons) {
                                            getPDF(orderLine.orderId, orderLine.orderType, orderLine.billToId);
                                        }
                                    }}
                                    title={showIcons ? localized.DownloadPDF : localized.NoPDFMessage}
                                >
                                    <PDFIcon />
                                </div>
                                {shareViaWhatsApp && (
                                    <div
                                        style={{
                                            cursor: showIcons ? 'pointer' : 'not-allowed',
                                            opacity: showIcons ? 1 : 0.5,
                                        }}
                                        aria-disabled={!showIcons}
                                        onClick={() => {
                                            if (showIcons) {
                                                getPDFLink(orderLine.orderId, orderLine.orderType, orderLine.billToId);
                                            }
                                        }}
                                        title={showIcons ? localized.DownloadPDF : localized.NoPDFMessage}
                                    >
                                        <WhatsAppIcon />
                                    </div>
                                )}
                            </>
                        )}
                    </div>


                </div>
            );
        }
    };

    return (
        <div className="fele-order-history">
            {!props.fetching && props.orderLines && props.orderLines.length > 0 && (
                <div className="export-button button-wrapper">
                    <button>
                        <CSVLink
                            id="orders-csv-link"
                            separator={props.localized.ExcelSeparator}
                            data={csvData}
                            filename={"Order History.xls"}
                            headers={getCSVHeaders()}
                            className="button button-alt"
                        >
                            {props.localized.Export}
                        </CSVLink>
                    </button>
                </div>
            )}
            <div className="br-history-header">
                <div data-cy="order-number" className="br-order-number">
                    <div className="label">{props.localized.OrderNumber}</div>
                </div>
                <div className="br-po-number">
                    <div className="label">{props.localized.PONumber}</div>
                </div>
                <div className="br-date">
                    <div className="label">{props.localized.DateOrdered}</div>
                </div>
                <div className="br-requested-date">
                    <div className="label">{props.localized.RequestedDate}</div>
                </div>
                {
                    (props.showEstimatedShipDate) &&
                    <div className="br-requested-date">
                        <div className="label">{props.localized.EstimatedShipDateOrderH}</div>
                    </div>
                }
                <div className="br-item-number">
                    <div className="label">{props.localized.ItemNumber}</div>
                </div>
                <div className="br-item-description">
                    <div className="label">{props.localized.ItemDescription}</div>
                </div>
                <div className="br-item-quantity">
                    <div className="label">{props.localized.QtyLabel}</div>
                </div>
                <div className="br-item-quantity-backordered">
                    <div className="label">{props.localized.QtyBackOrderedLabel}</div>
                </div>
                <div className="br-unit-price">
                    <div className="label">{props.localized.UnitPrice}</div>
                </div>
                <div className="br-product-total">
                    <div className="label">{props.localized.ProductTotal}</div>
                </div>
                <div className="br-line-status">
                    <div className="label">{props.localized.Status}</div>
                </div>
                <div className="br-invoice-number">
                    <div className="label">{props.localized.InvoiceNumber}</div>
                </div>
                {
                    props.ordersByLinePDF &&
                    <div className="br-invoice-pdf">
                        <div className="label">{props.localized.PDF}</div>
                    </div>
                }
            </div>
            {props.fetching || !isFileCheckComplete ? (
                <Loading type="brand" />
            ) : props.orderLines && props.orderLines.length === 0 ? (
                <div data-cy="order-details" className="details no-results">
                    {props.localized.NoOrdersFound}
                </div>
            ) : (
                <div>
                    <div data-cy="history" className="br-history">
                        {props.orderLines && props.orderLines.map((item, key) => renderOrderLine(item, key, props.localized, props.selectedCustomer, props.ordersByLinePDF, props.shareViaWhatsApp))}
                    </div>
                    {props.renderPaging && props.renderPaging()}
                </div>
            )}
        </div>
    );
};

export default OrderLines;