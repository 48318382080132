import React from "react";
import PropTypes from "prop-types";

const ItemNumberInput = ({
  itemNumberSubmit,
  itemNumberError,
  returnPolicy,
  obsolete,
  needsValidation,
  itemNeedsValidation,
  setItemNumberSubmit,
  handleEnter,
  validateItemNumber,
}) => {
  const inputClass = (() => {
    if (obsolete && returnPolicy === "F") {
      return "warning-border";
    }

    if (itemNumberError) {
      return "is-invalid";
    }

    const isValidState =
      !needsValidation || !itemNeedsValidation || !obsolete || !itemNumberError;

    if (
      isValidState &&
      itemNumberError !== null &&
      itemNumberSubmit.length > 0
    ) {
      return "is-valid";
    }

    return "";
  })();

  const handleChange = (e) => {
    if (!e || !e.target || typeof e.target.value !== "string") {
      return;
    }

    const trimmedValue = e.target.value.trim();
    if (typeof setItemNumberSubmit === "function") {
      setItemNumberSubmit(trimmedValue);
    }
  };

  const handlePaste = (e) => {
    if (!e || !e.clipboardData) {
      return;
    }

    e.preventDefault();
    const pastedText = e.clipboardData.getData("text")?.trim() || "";
    setItemNumberSubmit(pastedText);
  };

  return (
    <input
      className={`form-control ${inputClass}`}
      data-cy="itemnumber-submit"
      type="text"
      onChange={handleChange}
      onBlur={validateItemNumber}
      onKeyUp={handleEnter}
      onPaste={handlePaste}
      value={itemNumberSubmit}
      maxLength={25}
      required
    />
  );
};

ItemNumberInput.propTypes = {
  itemNumberSubmit: PropTypes.string,
  itemNumberError: PropTypes.bool,
  obsolete: PropTypes.bool,
  needsValidation: PropTypes.bool,
  itemNeedsValidation: PropTypes.bool,
  setItemNumberSubmit: PropTypes.func,
  handleEnter: PropTypes.func,
  validateItemNumber: PropTypes.func,
};

export default ItemNumberInput;
