import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PenIcon from "../icons/PenIcon";
import IconButton from "@mui/material/IconButton";
import ActionButton from "../shared/button/ActionButton";
import { Row, Col, Form } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { isEmpty, isString } from "lodash";

import UserService from "../../services/UserService";

const EditName = ({
  isAdmin,
  userActions,
  updateFullNameState,
  user,
  localized,
}) => {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const { firstName, lastName, id: userId } = user;
  const [formData, setFormData] = useState({
    firstName: (firstName || "").trim(),
    lastName: (lastName || "").trim(),
  });

  const isInvalidString = (value) => {
    return !isString(value) || isEmpty(value.trim());
  };

  const matchCurrentName = (name, value) => {
    const userFieldValue = user[name] || "";
    return value.trim() === userFieldValue.trim();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "firstName") {
      setErrors((prev) => ({
        ...prev,
        firstName: isInvalidString(value),
        noChanges: matchCurrentName("firstName", value),
      }));
    } else if (name === "lastName") {
      setErrors((prev) => ({
        ...prev,
        lastName: isInvalidString(value),
        noChanges: matchCurrentName("lastName", value),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => ({
      firstName: isInvalidString(formData.firstName),
      lastName: isInvalidString(formData.lastName),
    });

    const errors = validateForm();

    if (errors.firstName || errors.lastName) {
      setErrors(errors);

      return;
    }

    if (hasNoChanges()) {
      setErrors({ noChanges: true });

      return;
    }

    const payload = {
      ...formData,
      userId,
    };

    const response = await UserService.updateFullName(payload, localized);

    if (!response.isSuccess) {
      return;
    }

    const updatedName = {
      firstName: response.firstName,
      lastName: response.lastName,
      userId: userId,
      isAdmin: isAdmin,
    };

    // Update the user's name in the Redux store.
    // If the user is an admin, update selectedUser; otherwise, update currentUser.
    userActions.updateFullName(updatedName);

    // Update the selected user's details in the local state using setState.
    // This is only applicable to admins. This does not use Redux store currently.
    // (ToDo: Update this to use Redux store)
    if (isAdmin) {
      updateFullNameState(updatedName);
    }

    setErrors({});
    setOpen(false);
  };

  const hasNoChanges = () => {
    return (
      formData.firstName.trim() === (firstName || "").trim() &&
      formData.lastName.trim() === (lastName || "").trim()
    );
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setFormData({
      firstName: (firstName || "").trim(),
      lastName: (lastName || "").trim(),
    });
    setErrors({});
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <PenIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <IconButton
          aria-label={localized.Close}
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon fontSize="large" />
        </IconButton>

        <DialogTitle
          sx={{
            fontFamily: '"Gotham XNarrow SSm A", "Gotham XNarrow SSm B"',
            fontSize: "2.8rem",
            fontWeight: 700,
            padding: "16px 24px 0 24px",
          }}
        >
          {localized.UpdateName}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            sx={{
              fontFamily: '"Gotham XNarrow SSm A", "Gotham XNarrow SSm B"',
              fontSize: "1.8rem",
              fontWeight: 500,
              paddingBottom: "4rem",
              maxWidth: "40ch",
            }}
          >
            {localized.UpdateNameDescription}
          </DialogContentText>

          <form
            className="d-flex flex-column gap-4 "
            onSubmit={handleSubmit}
            noValidate
          >
            <Form.Group as={Row} controlId="firstName">
              <Form.Label column sm="4">
                {localized.FirstName}
              </Form.Label>

              <Col sm="8">
                <Form.Control
                  type="text"
                  name="firstName"
                  className={errors.firstName ? "is-invalid" : ""}
                  value={formData.firstName}
                  onChange={handleChange}
                  style={{ height: "4.8rem", fontSize: "1.8rem" }}
                  autoFocus
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="lastName">
              <Form.Label column sm="4">
                {localized.LastName}
              </Form.Label>

              <Col sm="8">
                <Form.Control
                  type="text"
                  name="lastName"
                  className={errors.lastName ? "is-invalid" : ""}
                  value={formData.lastName}
                  onChange={handleChange}
                  style={{ height: "4.8rem", fontSize: "1.8rem" }}
                />
              </Col>
            </Form.Group>

            <CSSTransition
              in={errors.noChanges}
              timeout={300}
              classNames="fadeTransition"
              unmountOnExit
            >
              <p className="text-danger pt-1" style={{ maxWidth: "40ch" }}>
                {localized.NoChangesMade}
              </p>
            </CSSTransition>

            <DialogActions sx={{ padding: "0.5rem 0 0 0" }}>
              <ActionButton
                variant="blue-solid"
                type="submit"
                text={localized.SaveChanges}
                disabled={
                  !!errors.firstName || !!errors.lastName || !!errors.noChanges
                }
              />
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditName;

EditName.propTypes = {
  userActions: PropTypes.object,
  isAdmin: PropTypes.bool,
  updateFullNameState: PropTypes.func,
  user: PropTypes.object,
  localized: PropTypes.object,
};
