import React from 'react';
import DateDisplay from '../DateDisplay';
import Strings from '../../translations/Orders.Strings';
import StatusHelper from '../../helpers/StatusHelper';
import Api from '../../services/Api';
import Notifications from '../../services/Notifications';
import OrderAckIcon from '../icons/OrderAckIcon';
import WhatsAppIcon from '../icons/WhatsAppIcon';
import { withSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';

export default class OrderSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showIcons: false
        };
    }

    componentDidMount() {
        const { order } = this.props;
        if (order) {
            this.checkOrderFileExists(order);
        }
    }

    getPDF = (orderNumber, orderType, billToId) => {
        Api.downloadFileName(`/api/orders/GetPDF/${billToId}/${orderNumber}/${orderType}`, `${billToId}-${orderNumber}${orderType}`).then(this.handleError);
    }

    getPDFLink = (orderNumber, orderType, billToId) => {
        Api.fetch(`/api/orders/GetPDFShareLink/${billToId}/${orderNumber}/${orderType}`, `${billToId}-${orderNumber}${orderType}`)
            .then((res) => {
                window.open(res.shareUrl, '_blank');
            });
    }

    handleError = (res) => {
        if (!res)
            Notifications.error(this.props.localized.NoPDFMessage);
    }

    checkOrderFileExists = async (order) => {
        let orderWrapper = {
            OrderNumber: order.orderId,
            OrderType: order.orderType,
            BillToId: order.billToId,
            FileName: `${order.billToId}-${order.orderId}${order.orderType}.pdf`
        };
        const orderDetailsObj = { Orders: [orderWrapper] };

        const response = await Api.checkOrderFileExists(`/api/orders/checkOrderFileExists`, orderDetailsObj);
        if (response.ok) {
            const responseData = await response.json();
            const dataObj = responseData.find(file => String(file.fileName).trim().toLowerCase() == String(orderWrapper.FileName).trim().toLowerCase());
            this.setState({ showIcons: dataObj ? dataObj.exists : false });
        }
        else {
            this.setState({ showIcons: false });
        }
    }

    render() {
        const { order, orderDetailsPDF, shareViaWhatsApp, localized } = this.props;
        const { showIcons } = this.state;
        if (!order)
            return <div></div>

        return (
            <div>
                <div className="history-title">
                    <div className="order-number" >

                        <div className="heading2">{localized.OrderNumber}:</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '5%' }}>
                            <div className="content">{order.orderId + " " + order.orderType}</div>
                            {
                                orderDetailsPDF && !order.hideDownloadPDF &&
                                <div style={{ display: 'flex', cursor: showIcons ? 'pointer' : 'not-allowed', opacity: showIcons ? 1 : 0.5 }} aria-disabled={!showIcons}>
                                        <div className="content my-auto"
                                            style={{ display: 'flex', cursor: 'pointer' }}
                                            onClick={() => {
                                                if (showIcons) {
                                                    this.getPDF(order.orderId, order.orderType, order.billToId);
                                                }
                                            }}
                                            title={showIcons ? localized.DownloadPDF : localized.NoPDFMessage}
                                        >
                                        <span style={{ fontSize: '16px', marginRight: '6px', marginTop: '6px' }}>{localized.DownloadPDF}</span><OrderAckIcon />
                                    </div>
                                        {shareViaWhatsApp &&
                                            <div className="content my-auto"
                                                style={{ display: 'flex', cursor: 'pointer', marginLeft: '30px' }}
                                                onClick={() => {
                                                    if (showIcons) {
                                                        this.getPDFLink(order.orderId, order.orderType, order.billToId);
                                                    }
                                                }}
                                                title={showIcons ? localized.DownloadPDF : localized.NoPDFMessage}
                                            >
                                            <span style={{ fontSize: '16px', marginRight: '6px', marginTop: '6px' }}>{localized.ShareViaWhatsApp}</span><WhatsAppIcon />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div data-cy="history-summary" className="history-summary">
                    <div className="left-side">
                        <div className="order-status">
                            <div className="label">{localized.OrderStatus}:</div>
                            <div className="content">{StatusHelper.formatOrderStatus(order.status, localized)}</div>
                        </div>
                        <div className="order-date">
                            <div className="label">{localized.OrderDate}:</div>

                            <div className="content">
                                <DateDisplay date={order.submittedOn} />
                            </div>
                        </div>
                        <div className="currency">
                            <div className="label">{localized.Currency}:</div>
                            <div className="content"> {order.currency} </div>
                        </div>
                    </div>
                    <div className="right-side">
                        {
                            order.submittedBy &&
                            <React.Fragment>
                                <div className="ordered-by">
                                    <div className="label">{localized.OrderedBy}</div>
                                    <div className="content">{order.submittedBy.name}</div>
                                </div>
                                <div className="email">
                                    <div className="label">Email:</div>
                                    <div className="content"><a href={"mailto:" + order.submittedBy.email}>{order.submittedBy.email}</a></div>
                                </div>
                                <div className="submitted-on">
                                    <div className="label">{localized.SubmitedOn}</div>
                                    <div className="content">
                                        <DateDisplay date={order.submittedOn} />
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {
                            !order.submittedBy &&
                            <div className="not-ordered-note">{localized.NoPurchaserInfo}</div>
                        }
                    </div>
                </div>
            </div >
        );
    }
}