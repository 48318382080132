import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  ArcElement,
  LineElement,
  Title,
  Tooltip as ChartToolTip,
  Filler,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import PropTypes from "prop-types";
import PricingHelper from "../../helpers/PricingHelper";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  PointElement,
  ArcElement,
  LineElement,
  Title,
  ChartToolTip,
  Legend,
  Filler
);

export const QuoteTotalsPieGraph = (props) => {
  const dashboardLabels = props.dashboardLabels;
  const chartData = props.chartData;
  const history = useHistory();
  const country = props.country;
  const currencySymbol = props.currencySymbol;
  const currency = props.currency;

  const getTotal = () =>
    chartData.headerResponse.reduce((total, x) => total + x.total, 0);

  const formatTotal = () => {
    if (!country?.trim() || !currency?.trim()) {
      return 0;
    }

    return PricingHelper.formatValue(country, currency, getTotal());
  };

  const getLabels = () =>
    chartData.headerResponse.map((x) => x.orderNumber.toString());

  const handleClick = (label, quoteType, quoteCompany) => {
    history.push(`/quote/${label}/${quoteCompany}/${quoteType}`);
  };

  const getData = () =>
    chartData.headerResponse?.length
      ? chartData.headerResponse.map((x) => x.total)
      : [];

  const options = {
    onClick: (e, elements) => {
      let dataIndex = elements[0].index;
      let label = e.chart.data.labels[dataIndex];
      let quoteType = chartData.headerResponse.find(
        (x) => x.orderNumber == label
      ).orderType;
      let quoteCompany = chartData.headerResponse.find(
        (x) => x.orderNumber == label
      ).orderCompany;
      handleClick(label, quoteType, quoteCompany);
    },
    layout: {
      padding: {
        bottom: 20,
        left: 20,
        right: 20,
      },
    },
    plugins: {
      legend: {
        events: [],
        position: "left",
        align: "left",
        padding: {
          right: 10,
        },
        labels: {
          boxHeight: 10,
          boxWidth: 10,
        },
      },
      title: {
        display: true,
        text: dashboardLabels.RecentUnrealizedQuotes,
      },
      tooltip: {
        callbacks: {
          label: (value) => {
            if (
              country &&
              currency &&
              value &&
              country != "" &&
              currency != ""
            ) {
              return (
                dashboardLabels.QuoteAmount +
                PricingHelper.formatValue(country, currency, value.raw)
              );
            }
          },
        },
      },
    },
  };

  const data = {
    labels: getLabels(),
    datasets: [
      {
        data: getData(),
        backgroundColor: [
          "rgba(0, 0, 102, .8)",
          "rgba(173, 216, 230, .8)",
          "rgba(169, 169, 169, .8)",
          "rgba(0, 0, 0, .8)",
          "rgba(126,189,66,.8)",
          "rgba(139,69,19, .8)",
          "rgba(245,213,196, .8)",
          "rgba(210 ,180 ,140, .8)",
          "rgba(255 ,253 ,208, .8)",
          "rgba(105 ,105 ,105, .8)",
        ],
        borderColor: [
          "rgba(0, 0, 102, 1)",
          "rgba(173, 216, 230, 1)",
          "rgba(169, 169, 169, 1)",
          "rgba(0, 0, 0, 1)",
          "rgba(126,189,66,1)",
          "rgba(139,69,19, 1)",
          "rgba(245,213,196, 1)",
          "rgba(210 ,180 ,140, 1)",
          "rgba(255 ,253 ,208, 1)",
          "rgba(105 ,105 ,105, 1)",
        ],
        hoverOffset: 30,
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div
        style={{
          fontSize: "24px",
          textAlign: "center",
          paddingTop: "1rem",
          fontWeight: "bold",
        }}
      >
        <div>
          {dashboardLabels.RecentUnrealizedTotal} {formatTotal()}
          {currencySymbol}
        </div>
      </div>

      <div
        style={{
          paddingRight: "2.5rem",
          paddingLeft: "1rem",
          paddingBottom: "2rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "40rem", height: "40rem" }}>
          <Pie options={options} data={data} />
        </div>
      </div>
    </>
  );
};

QuoteTotalsPieGraph.propTypes = {
  chartData: PropTypes.object,
  currencySymbol: PropTypes.string,
  currency: PropTypes.string,
  country: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    selectedCustomer: state.user.selectedCustomer,
  };
}

export default connect(mapStateToProps, null)(QuoteTotalsPieGraph);
