import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import UserCustomers from './UserCustomers';
import StorefrontPersona from './StorefrontPersona';
import CheckBox from '../form-controls/CheckBox';
import UserService from '../../services/UserService';
import Notifications from '../../services/Notifications';
import UserIcon from '../icons/User';
import Authorize from '../Authorize';
import MessageStandard from '../MessageStandard';
import SalesTerritorySelector from './SalesTerritorySelector';
import * as userActions from '../../actions/userActions';
import DateHelper from "../../helpers/DateHelper";
import UserHelper from "../../helpers/UserHelper";
import SubmitModal from '../shared/SubmitModal';
import Loading from '../Loading';
import { Container, Row, Col } from 'react-bootstrap';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import EditName from "../users/EditName";

import WarrantyAccess from './WarrantyAccess';
import Clone from './Clone';

export class Details extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            currentStorefront: null,
            currentStorefrontName: '',
            currentStorefrontNumber: '09000',
            currentPersona: null,
            secondaryEmail: '',
            isOpen: false,
            isValid: true,
            isStorefrontModalOpen: false,
            selectedStorefront: {},
            selectedPersona: {},
            loading: true,
            isWarrantyModalOpen: false,
            isCloneUserModalOpen: false,
            updateWarrantyRoles: '',
            usersToClone: this.props.usersToClone,
            updateUsersToClone: [],
            isRoleClone: false,
            CloneProcessing: false
        };

        this.loadUser(props.userId);
    }

    loadUser = (id) => {
        this.setState({ loading: true });
        // look up user and load into page.
        UserService.getUserByid(id).then((res) => {
            const defaultStorefront = this.props.defaultStorefront || this.props.StorefrontConfig.getStorefrontByNumber((res && res.storefrontNumber) ? res.storefrontNumber : '09000');
            this.setState(
                {
                    user: res,
                    currentStorefront: res.storefrontNumber && defaultStorefront,
                    currentStorefrontName: defaultStorefront.displayName,
                    currentStorefrontNumber: defaultStorefront.number,
                    currentPersona: { value: (res.persona) ? res.persona.id : '', label: (res.persona) ? res.persona.displayName : '' },
                    secondaryEmail: res.secondaryEmail,
                    loading: false,
                });
        });
    }

    setUsersToClone = async (users) => {
        await this.setState({ updateUsersToClone: users });

    }

    setIsRoleClone = async (event) => {
        await this.setState({ isRoleClone: !this.state.isRoleClone });
    }

    reloadUser = () => {
        this.loadUser(this.state.user.id);
    }

    toggleIsActive = () => {
        UserService.updateActive(
            this.state.user.id,
            () => { this.props.userActions.setSelectedUser({ ...this.state.user, isActive: !this.state.user.isActive }); this.setState({ user: { ...this.state.user, isActive: !this.state.user.isActive } }); }
        );

    }

    updateStorefrontPersona = (storefront, persona) => {
        this.setState({ selectedStorefront: storefront, selectedPersona: persona });
    }

    displayWarrantyAccessMessage = () => {
        Notifications.message("Please submit a request for modifications to legacy warranty access.");
    }

    updatePersona = (value, name) => {
        UserService.updatePersonaApi(this.state.user.id, value);
        this.state.user.personaName = name;
        this.props.userActions.setSelectedUser(this.state.user);
    }

    setStorefront = (e) => {
        const value = e.currentTarget.value;

        const storefront = this.props.StorefrontConfig.getStorefrontByNumber(value);
        let strfrntNumber = this.state.currentStorefrontNumber;
        if (strfrntNumber.startsWith('0')) {
            strfrntNumber = strfrntNumber.substring(1);
        }
        UserService.updateStorefront(this.state.user.email, strfrntNumber);
        this.state.user.storefrontNumber = strfrntNumber;
        this.state.userActions.setSelectedUser(this.state.user);

        this.setState({ currentStorefrontName: storefront.displayName, currentStorefrontNumber: storefront.number }, () => { this.props.setSelectedStorefront(storefront.number) });
        this.setState({ currentStorefront: value });
    }

    openStorefrontModal = () => {
        this.setState({ isStorefrontModalOpen: true });
    }

    hideStorefrontModal = () => {
        this.setState({ isStorefrontModalOpen: false });
    }

    handleStorefrontSave = async () => {
        if (this.state.selectedPersona && this.state.selectedPersona.label.length > 0 && this.state.selectedStorefront && this.state.selectedStorefront.label.length > 0) {
            this.hideStorefrontModal();
            // update storefront
            const storefront = this.props.StorefrontConfig.getStorefrontById(this.state.selectedStorefront.value);
            UserService.updateStorefrontApi(this.state.user.email, storefront.number);
            this.state.user.storefrontNumber = storefront.number;
            this.props.userActions.setSelectedUser(this.state.user);

            this.setState({ currentStorefrontName: storefront.displayName, currentStorefrontNumber: storefront.number }, () => { this.props.setSelectedStorefront(storefront.number) });
            this.setState({ currentStorefront: storefront });

            // update persona
            await this.updatePersona(this.state.selectedPersona.value, this.state.selectedPersona.label);
            this.setState({ currentPersona: this.state.selectedPersona });

        } else {
            Notifications.error("Please select a storefront and related persona.");
        }
    }

    updateWarranty = (roles) => {
        this.setState({ updateWarrantyRoles: roles });
    }

    handleWarrantySave = () => {
        // call endpoint with roles string.
        UserService.updateWarrantyAccess(this.state.user.id, this.state.updateWarrantyRoles, this.reloadUser);
        // reload user

        // hid warranty modal
        this.hideWarrantyModal();
    }
    handleCloneSave = async () => {
        this.setState({ CloneProcessing: true });
        const localized = this.props.StorefrontConfig.labels;
        try {           
            return await UserService.cloneUser(this.state.user.id, this.state.updateUsersToClone, this.state.isRoleClone, this.reloadUser).then(response => {
                if (response) {
                    const jsonObject = JSON.parse(response);
                    this.setState({ updateUsersToClone: [], isRoleClone: false });
                    this.hideCloneModal();
                    this.setState({ CloneProcessing: false });                    
                    
                    if (jsonObject.IsSuccess) {
                        Notifications.message(localized.SuccessUserCloned);
                    }
                    else {
                        Notifications.error(localized.FailedUserCloned);
                    }
                }
            });            
        } catch (error) {
            Notifications.error(localized.ErrorUserCloned, error);
        }
    };
    
    openWarrantyModal = () => {
        this.setState({ isWarrantyModalOpen: true });
    }

    hideWarrantyModal = () => {
        this.setState({ isWarrantyModalOpen: false });
    }

    openCloneModal = () => {
        this.setState({ isCloneUserModalOpen: true });
    }

    hideCloneModal = () => {
        this.setState({ isCloneUserModalOpen: false, isRoleClone: false, updateUsersToClone: [] });
    }

    openModal = () => {
        this.setState({
            isOpen: true
        })
    }

    hideModal = () => {
        this.setState({
            isOpen: false
        });
    }

    handleSaveClick = () => {
        this.hideModal();
        this.state.user.secondaryEmail = this.state.secondaryEmail;
        this.props.userActions.setSelectedUser(this.state.user);
        return UserService.setSecondaryEmailApi(this.state.user.id, this.state.secondaryEmail);
    }

    secondaryEmailUpdated = (e) => {
        const secondaryEmail = e.currentTarget.value;
        this.setState({ secondaryEmail: secondaryEmail });
        if (secondaryEmail?.length > 0 && !UserHelper.isValidEmail(secondaryEmail)) {
            this.setState({ isValid: false, isOpen: true });
        }
        else {
            this.setState({ isValid: true, isOpen: true });
        }
    }

    removeSecondaryEmail = () => {
        Notifications.confirmation("Do you want to remove secondary email?", () => {
            this.state.user.secondaryEmail = "";
            this.setState({ secondaryEmail: "" });
            this.props.userActions.setSelectedUser(this.state.user);
            UserService.setSecondaryEmailApi(this.state.user.id, "");
        });
    }

    updateFullNameState = (fullName) => {
        this.setState({
            user: {
                ...this.state.user,
                firstName: fullName.firstName,
                lastName: fullName.lastName
            }
        })

        this.props.refreshFilteredUsers();
    }

    render() {
        const localized = this.props.StorefrontConfig.labels;
        const salesTerritorySelector = this.props.userSettings.SalesTerritorySelector;

        if (this.state.loading)
            return (<Loading type="brand" />)

        if (!this.state.user)
            return (
                <div className="fele-select-user-message">
                    <MessageStandard
                        icon={<UserIcon />}
                        line1={localized.UserInstructionsMessage}
                    />
                </div>
            )

        return (
            <div className="fele-user-details active" >
                <div className="info">
                    <div className="title flex">
                        <div className="d-flex align-items-center gap-2">
                            <div className="name">
                                {
                                    this.state.user && this.state.user.firstName && this.state.user.lastName
                                        ? `${this.state.user.firstName} ${this.state.user.lastName}`
                                        : 'N/A'
                                }
                            </div>

                            <EditName isAdmin={true} userActions={this.props.userActions} updateFullNameState={this.updateFullNameState} user={this.state.user} localized={localized} />
                        </div>

                        <div className="toggle"><span className="label">{(this.state.user.isActive) ? localized.ActiveLabel : localized.InactiveLabel}:</span>
                            <button className={`fele-status-toggle ${this.state.user.isActive ? "" : "inactive"}`} onClick={this.toggleIsActive}>
                                <div className="switch"></div>
                            </button>
                        </div>
                    </div>

                    <div className="subemail flex">
                        <div className="primary">
                            <div className="label text">{localized.Primary}:</div>
                            <div className="email">{this.state.user.email}</div>
                        </div>

                        <div>
                            <div className="label text">{localized.Secondary}:</div>
                            <div className="email"><a>{this.state.user.secondaryEmail}</a></div>
                        </div>

                        {
                            this.state.user.secondaryEmail &&
                            <div className="remove">
                                <div className="button-wrapper">
                                    <button className="remove" onClick={() => { this.removeSecondaryEmail() }} > <span>X </span><span>{localized.Remove}</span></button>
                                </div>
                            </div>
                        }

                        <div className="remove">
                            <div className="button-wrapper">
                                <button className="remove" >
                                    <a className="link" style={{ fontSize: '1.6rem' }} onClick={() => this.openModal()}
                                    >{this.state.user.secondaryEmail ? localized.Edit : localized.Add}  {" " + localized.SecondaryEmail}</a>
                                </button>

                            </div>
                        </div>

                        <div>
                            <div className="email"><a>{this.state.user.secondaryEmail}</a></div>
                        </div>

                        <div className="remove">
                            <div className="button-wrapper">
                                <button className="remove" >
                                    <a className="link" style={{ fontSize: '1.6rem' }} onClick={() => this.openCloneModal()}>
                                        {" " + "Clone User Profile"}
                                    </a>
                                </button>
                            </div>
                        </div>

                        <SubmitModal
                            isOpen={this.state.isOpen}
                            onSubmit={this.handleSaveClick}
                            onClose={this.hideModal}
                            submitText={localized.Save}
                            closeText={localized.Cancel}
                        >
                            <Container>
                                <Row>
                                    <Col xs={8} >
                                        <div className="heading2" style={{ paddingBottom: '1.2rem' }}>
                                            {this.state.user.secondaryEmail ? localized.Edit : localized.Add}  {" " + localized.SecondaryEmail}
                                        </div>
                                    </Col>
                                </Row>

                                <Row> &nbsp;</Row>

                                <Row>
                                    <Col xs={1}></Col>

                                    <Col xs={6} md={10}>
                                        <div className="input-wrapper">
                                            <input type="text" className="input-secondary-email" onChange={this.secondaryEmailUpdated} value={this.state.secondaryEmail || ""} />
                                        </div>
                                    </Col>
                                </Row>

                                {
                                    !this.state.isValid &&
                                    <Row>
                                        <Col xs={1}></Col>

                                        <Col xs={8}>
                                            <div className="invalid-email-message">
                                                {localized.InvalidEmailMessage}
                                            </div>
                                        </Col>
                                    </Row>
                                }

                                <Row> &nbsp;</Row>

                                <Row>
                                    <div style={{ color: '#848484', fontWeight: '500', lineHeight: '21px' }}>
                                        {localized.Body}
                                    </div>
                                </Row>

                                <Row> &nbsp;</Row>
                            </Container>
                        </SubmitModal>

                        <SubmitModal
                            isOpen={this.state.isStorefrontModalOpen}
                            onSubmit={this.handleStorefrontSave}
                            onClose={this.hideStorefrontModal}
                            submitText={localized.Save}
                            closeText={localized.Cancel}
                        >
                            <StorefrontPersona updateStorefrontPersona={this.updateStorefrontPersona} currentStorefront={{ value: this.state.currentStorefront.id, label: this.state.currentStorefront.displayName }} currentPersona={this.state.currentPersona} currentUser={this.props.currentUser} />
                        </SubmitModal>

                        <SubmitModal
                            isOpen={this.state.isWarrantyModalOpen}
                            onSubmit={this.handleWarrantySave}
                            onClose={this.hideWarrantyModal}
                            submitText={localized.Save}
                            closeText={localized.Cancel}
                        >
                            <WarrantyAccess user={this.state.user} updateSelectedRoles={this.updateWarranty} />
                        </SubmitModal>
                     
                        <SubmitModal
                            isOpen={this.state.isCloneUserModalOpen}
                            onSubmit={this.handleCloneSave}
                            onClose={this.hideCloneModal}
                            submitText={"Apply"}
                            closeText={localized.Cancel}
                        >
                            {this.state.CloneProcessing ? (
                                <Loading />
                            ) : (
                                    <Clone isRoleClone={this.state.isRoleClone} setIsRoleClone={this.setIsRoleClone} setUsersToClone={this.setUsersToClone} user={this.state.user} usersToClone={this.state.usersToClone} updateUsersToClone={this.state.updateUsersToClone} CloneProcessing={this.state.CloneProcessing} />

                            )}
                        </SubmitModal>
                    </div>

                    <div className="sub flex">
                        <div>
                            <div className="role button-wrapper">
                                <div>
                                    <b>Storefront:</b>

                                    <span className="current" style={{ marginLeft: '4px' }}>
                                        {this.state.currentStorefrontName}
                                    </span>
                                </div>

                                <div>
                                    <b>Persona:</b>

                                    <span className="current" style={{ marginLeft: '4px' }}>
                                        {this.state.currentPersona.label}
                                    </span>
                                </div>

                                <div className="button-wrapper">
                                    <button className="remove">
                                        <a className="link" style={{ fontSize: '1.6rem' }} onClick={() => this.openStorefrontModal()}>
                                            Update Storefront/Persona
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {false &&
                            <Authorize as="Administrator">
                                <CheckBox checked={UserHelper.hasRoles(['HasWarrantyAccess'], this.state.user)} label="Warranty Access:" onClick={this.toggleWarrantyAccess} />
                            </Authorize>
                        }

                        <Authorize as="Administrator">
                            <div>
                                <CheckBox checked={UserHelper.hasRoles(['HasWarrantyAccess'], this.state.user)} label="Warranty Claims:" onClick={this.openWarrantyModal} />
                                <CheckBox checked={UserHelper.hasRoles(['HasLegacyWarrantyAccess'], this.state.user)} label="Legacy Warranty:" onClick={this.displayWarrantyAccessMessage} />

                                <div className="button-wrapper">
                                    <button className="remove">
                                        <a className="link" style={{ fontSize: '1.6rem' }} onClick={() => this.openWarrantyModal()}>
                                            Update Warranty Access
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </Authorize>

                        <div>
                            <div className="role">
                                <div className="label"><div className="text">{localized.LastLogin}</div></div>
                                {
                                    this.state.user.lastActivity &&
                                    <div className="email">{DateHelper.format(new Date(this.state.user.lastActivity), 'MM/dd/yyyy hh:mm a', true)}</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <UserCustomers user={this.state.user} currentUser={this.props.currentUser} localized={localized} />
                {
                    salesTerritorySelector === true &&
                   <Authorize as="Administrator">
                        <SalesTerritorySelector localized={localized} user={this.state.user} />
                    </Authorize>
                }
            </div>
        )
    }
}

Details.propTypes = {
    userActions: PropTypes.object,
    user: PropTypes.object,
    refreshFilteredUsers: PropTypes.func
};

function mapStateToProps(state) {
    return {
        user: state.user.selectedUser,
        filteredUsers: state.user.filteredUsers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStorefrontConfig(Details));