import React from 'react';
import PropTypes from 'prop-types';
import CalendarIcon from '../icons/CalendarIcon';
import Strings from './Calendar.Strings';
import { InputGroup } from 'react-bootstrap';
import { registerLocale } from 'react-datepicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ptb from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
registerLocale("pt-BR", ptb);
registerLocale("en-US", en);
registerLocale("es", es);
registerLocale("it-IT", it);
import DateHelper from '../../helpers/DateHelper';
import it from 'date-fns/locale/it';

const getDateFormat = (propsDateFormat) => {
    let dateFormat = propsDateFormat ? propsDateFormat : "MM/dd/yyyy";

    return dateFormat;
}

const Calendar = props => {
    const localized = Strings.localize();
    const rootMinDate = new Date(props.minDate);
    rootMinDate.setHours(0, 0, 0, 0);

    const calendarSelectedDate = props.date === null ? null : (new Date(props.date) >= rootMinDate ? props.date : rootMinDate);

    const dateInputChanged = (date, e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }

        if (!rootMinDate || date >= rootMinDate) {
            let _d = DateHelper.getUTCDate(date);
            return props.callBackFunction(_d);
        }
    }

    const isWeekday = (date) => {
        if (props.filterWeekends) {
            const day = date.getDay();

            return day !== 0 && day !== 6;
        }
        else {
            return true;
        }
    };

    return (
        <div className="fele-calendar" style={props.disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
            <label className="datepicker-wrapper" style={{ width: "100%" }}>
                <div className="col-md-12">
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localized.lang}>
                        <DatePicker
                            value={calendarSelectedDate}
                            onChange={dateInputChanged}
                            minDate={rootMinDate}
                            renderInput={(params) => (
                                <InputGroup className="mb-6">
                                    {props.showIcon && (
                                        <InputGroup.Text>
                                            <CalendarIcon />
                                        </InputGroup.Text>
                                    )}
                                    <input {...params.inputProps} className="form-control" />
                                </InputGroup>
                            )}
                        />
                    </LocalizationProvider>
                </div>
            </label>
        </div>
    );
}

Calendar.propTypes = {
    date: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ])
};

export default Calendar;