import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import Storefront from '../../services/Storefront';
import Box from '@mui/material/Box';
import Loading from '../Loading';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Notifications from '../../services/Notifications';
import { useStorefrontAdministrationConfig } from '../../hooks/StorefrontAdministrationConfigContext';
import TranslatableDataGrid from '../shared/TranslatableDataGrid';


export const FreightHandlingCodeAdministration = () => {
    const [rows, setRows] = useState([]);
    const [fetching, setFetching] = useState(false);
    const localized = useStorefrontAdministrationConfig().labels;
    const storeData = useStorefrontSettings();
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const responseMessages = {
        "DeleteForeignKeyConstraintViolation": localized.FreightCodeDeleteForeignKeyConstraintViolation,
        "UpdateForeignKeyConstraintViolation": localized.FreightCodeUpdateForeignKeyConstraintViolation,
        "DuplicateKeyViolation": localized.FreightCodeDuplicateKeyViolation,
        "GenericFailure": localized.FreightCodeGenericFailure,
        "DeleteSuccess": localized.FreightCodeDeleteSuccess,
        "UpdateSuccess": localized.FreightCodeUpdateSuccess,
        "AddSuccess": localized.FreightCodeAddSuccess
    };

    useEffect(() => {
        getFreightHandlingCodes();
    }, []);

    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{
                        tooltip: { title: 'Change density' },
                    }}
                />

                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />
                {renderAddButton()}
            </GridToolbarContainer>
        );
    }

    const getFreightHandlingCodes = () => {
        setFetching(true);
        Storefront.getFreightHandlingCodes(storeData.storefrontNumber).then(loaded);
    }

    const updateFreightHandlingCode = async (form, type) => {
        var request = {
            id: form.Id ?? null,
            code: form.code ?? null,
            storefrontNumber: storeData.storefrontNumber,
            description: form.description ?? null,
            operationType: type
        };
        return await Storefront.updateFreightHandlingCode(request).then((res) => {
            if (res) {      
                const notificationType = res.response.endsWith("Success") ? "message" : "error";
                const message = responseMessages[res.response];

                if (message) {
                    Notifications[notificationType](message);
                }
                else {
                    Notifications.error(localized.FreightCodeFailUpdateNotification);
                }
                getFreightHandlingCodes();
            }   
        });
    }

    const loaded = (data) => {
        if (data) {
            mapRows(data);
        }
        setFetching(false);
    }

    const mapRows = (data) => {
        var rows = [];
        data.freightHandlingCodes.forEach((freightHandlingCode, index) => {

            rows.push({ id: index, codeId: freightHandlingCode.id, code: freightHandlingCode.code, description: freightHandlingCode.description, });
        });
        setRows(rows);
    }

    const handleClickOpenUpdate = (e,row) => {    
        e.stopPropagation();
        setSelectedRow(row);
        setOpenUpdate(true);
    };

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
    };
    const handleClickOpenDelete = (e, row) => {
        e.stopPropagation();
        setSelectedRow(row);    
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleClickOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };

    const renderDeleteButton = (row) => {
        return (
            <>                
                <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("Id", row.codeId);
                            const formJson = Object.fromEntries(formData.entries());
                            updateFreightHandlingCode(formJson, "delete");
                            handleCloseDelete();
                        },
                    }}
                >
                    <DialogTitle>{localized.AreYouSure}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {localized.AreYouSureDeleteFreightCode}
                        </DialogContentText>
                        <DialogContentText ><b>{row.code}</b></DialogContentText>                       
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDelete}>{localized.StorefrontAdminCancel}</Button>
                        <Button type="submit">{localized.StorefrontAdminDelete}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const renderUpdateButton = (row) => {
        return (
            <>            
                <Dialog
                    open={openUpdate}
                    onClose={handleCloseUpdate}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("Id", row.codeId);
                            const formJson = Object.fromEntries(formData.entries());
                            updateFreightHandlingCode(formJson,"update");
                            handleCloseUpdate();
                        },
                    }}
                >
                    <DialogTitle>{localized.UpdateFreightHandlingCode}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {localized.ValuesEligibleForUpdateBelow}
                        </DialogContentText>
                        <TextField
                            defaultValue={row.code}
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="code"
                            name="code"
                            label="Code"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            defaultValue={row.description}
                            inputProps={{ maxLength: 500 }}
                            required
                            margin="dense"
                            id="description"
                            name="description"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUpdate}>{localized.StorefrontAdminCancel}</Button>
                        <Button type="submit">{localized.StorefrontAdminUpdate}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const renderAddButton = (params) => {
        return (
            <>
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={handleClickOpenAdd}
                    >
                        {localized.StorefrontAdminAdd}
                    </Button>
                </strong>
                <Dialog
                    open={openAdd}
                    onClose={handleCloseAdd}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            updateFreightHandlingCode(formJson, "add");
                            handleCloseAdd();
                        },
                    }}
                >
                    <DialogTitle>{localized.AddFreightHandlingCode}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {localized.FillRequiredFieldsBelow}
                        </DialogContentText>
                        <TextField
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="code"
                            name="code"
                            label={localized.StorefrontAdminCode}
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            inputProps={{ maxLength: 500 }}
                            required
                            margin="dense"
                            id="description"
                            name="description"
                            label={localized.StorefrontAdminDescription}
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAdd}>{localized.StorefrontAdminCancel}</Button>
                        <Button type="submit">{localized.StorefrontAdminAdd}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const columns = [
        { field: 'code', headerName: localized.StorefrontAdminCode, width: 200 },
        { field: 'description', headerName: localized.StorefrontAdminDescription, width: 1000 },
        {
            field: 'updateButton',
            headerName:  localized.StorefrontAdminUpdate ,
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenUpdate(e, params.row)}
                        >
                            {localized.StorefrontAdminUpdate}
                        </Button>
                    </strong>
                )
            },            
            disableClickEventBubbling: true
            
        },
        {
            field: 'deleteButton',
            headerName: localized.StorefrontAdminDelete,
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenDelete(e, params.row)}
                        >
                            {localized.StorefrontAdminDelete}
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true
        }
    ];



    return (
        <>
            {
                fetching && <Loading type="brand" />
            }
            {
                !fetching && (localized && Object.keys(localized).length > 0) &&
                <>
                    <h1>{localized.FreightHandlingCodeAdministrationHeader}</h1>
                    <Paper sx={{ height: 700, width: '100%' }}>
                        <TranslatableDataGrid rows={rows} columns={columns} insetToolbar={customToolbar} labels={localized} />
                    </Paper>
                    {openUpdate && renderUpdateButton(selectedRow)}
                    {openDelete && renderDeleteButton(selectedRow)}
                </>
            }
        </>
    );
}

export default FreightHandlingCodeAdministration;

