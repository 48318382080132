import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import Storefront from '../../services/Storefront';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Loading from '../Loading';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Notifications from '../../services/Notifications';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FormLabel } from '@mui/material';
import { useStorefrontAdministrationConfig } from '../../hooks/StorefrontAdministrationConfigContext';
import TranslatableDataGrid from '../shared/TranslatableDataGrid';

export const NewsAdministration = () => {
    const storeData = useStorefrontSettings();
    const localized = useStorefrontAdministrationConfig().labels;
    const [fetching, setFetching] = useState(false);
    const [dateValue, setDateValue] = useState(new Date());
    const [rows, setRows] = useState([]);
    
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const responseMessages = {
        "DuplicateKeyViolation": localized.NewsDuplicateKeyViolation,
        "GenericFailure": localized.NewsGenericError,
        "DeleteSuccess": localized.NewsDeleteSuccess,
        "UpdateSuccess": localized.NewsUpdateSuccess,
        "AddSuccess": localized.NewsAddSuccess
    };
    var addDateValue = new Date();
    useEffect(() => {
        getNews();
    }, []);

    const customToolbar = () => {
        return (
            <GridToolbarContainer>

                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarFilterButton
                    slotProps={{
                        tooltip: { title: 'Filter list' }, 

                    } }
                />
                <GridToolbarDensitySelector
                    slotProps={{
                        tooltip: { title: 'Change density' },
                    }}
                />

                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    slotProps={{
                       
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />
                {renderAddButton()}
            </GridToolbarContainer>
        );
    }

    const getNews = () => {
        setFetching(true);
        Storefront.getNews(storeData.storefrontNumber).then(loaded);        
    }

    const updateNews = async (form, type) => {
        var useDate = type==="add" ? addDateValue : dateValue;
        var request = {
            id: form.id ?? null,
            title: form.title ?? null,
            content: form.content ?? null,
            postDate: useDate ?? null,
            priority: Number(form.priority) ?? null,
            storefrontNumber: storeData.storefrontNumber,
            operationType: type
        };
        addDateValue = new Date();
        return await Storefront.updateNews(request).then((res) => {
            if (res) {
                const notificationType = res.response.endsWith("Success") ? "message" : "error";
                const message = responseMessages[res.response];

                if (message) {
                    Notifications[notificationType](message);
                }
                else {
                    Notifications.error(localized.NewsErrorNotification);
                }
                getNews();
            }
        });
    }

    const loaded = (data) => {
        if (data) {
            mapRows(data);
        }
        setFetching(false);
    }

    const mapRows = (data) => {
        var rows = [];
        data.news.forEach((newsEntry) => {

            rows.push({
                id: newsEntry.id,
                title: newsEntry.title,
                content: newsEntry.content,
                postDate: newsEntry.postDate,
                priority: newsEntry.postPriority,
                lastModifiedBy: newsEntry.lastModifiedBy,
                lastModifiedByDate: newsEntry.lastModifiedByDate,
                firstName: newsEntry.modifyUserFirstName,
                lastName: newsEntry.modifyUserLastName,
            });
        });
        setRows(rows);
    }

    const handleClickOpenUpdate = (e, row) => {
        e.stopPropagation();
        setDateValue(row.postDate);
        setSelectedRow(row);
        setOpenUpdate(true);
    };

    const handleDateChangeUpdate = (e) => {
        setDateValue(e);
    }

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
        setDateValue(new Date());
    };

    const handleClickOpenDelete = (e, row) => {
        e.stopPropagation();
        setSelectedRow(row);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleClickOpenAdd = (e) => {
        e.stopPropagation();
        setOpenAdd(true);
    };

    const handleDateChangeAdd = (e) => {
        addDateValue=e;
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
        addDateValue = new Date();
    };

    const renderDeleteButton = (row) => {
        return (
            <>
                <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("id", row.id);
                            formData.append("priority", row.priority);
                            const formJson = Object.fromEntries(formData.entries());
                            updateNews(formJson, "delete");
                            handleCloseDelete();
                        },
                    }}
                >
                    <DialogTitle>{localized.AreYouSure}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {localized.NewsAreYouSureDelete}
                        </DialogContentText>
                        <DialogContentText ><b>{row.code}</b></DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDelete}>{localized.StorefrontAdminCancel}</Button>
                        <Button type="submit">{localized.StorefrontAdminDelete}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const renderUpdateButton = (row) => {
        return (
            <>
                <Dialog
                    open={openUpdate}
                    onClose={handleCloseUpdate}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            formData.append("id", row.id);
                            const formJson = Object.fromEntries(formData.entries());
                            updateNews(formJson, "update");
                            handleCloseUpdate();
                        },
                    }}
                >
                    <DialogTitle>{localized.UpdateNewsEntries}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {localized.ValuesEligibleForUpdateBelow}
                        </DialogContentText>
                        <TextField
                            defaultValue={row.title}
                            inputProps={{ maxLength: 250 }}
                            required
                            margin="dense"
                            id="title"
                            name="title"
                            label={localized.Title}
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            defaultValue={row.content}
                            required
                            margin="dense"
                            id="content"
                            name="content"
                            label={localized.Content }
                            type="text"
                            fullWidth
                            variant="standard"
                        />                        
                        <TextField
                            defaultValue={row.priority}
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="priority"
                            name="priority"
                            label={localized.Priority}
                            type="number"
                            fullWidth
                            variant="standard"
                        />
                        <FormLabel required sx={{ fontSize: 10 }}>{localized.PostDate +":  "} </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                required
                                defaultValue={new Date(row.postDate)}
                                value={new Date(dateValue)}
                                onChange={() => true}
                                onAccept={(x) => handleDateChangeUpdate(x, row)}
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUpdate}>{localized.StorefrontAdminCancel}</Button>
                        <Button type="submit">{localized.StorefrontAdminUpdate}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const renderAddButton = () => {
        return (
            <>
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={handleClickOpenAdd}
                    >
                        {localized.StorefrontAdminAdd}
                    </Button>
                </strong>
                <Dialog
                    open={openAdd}
                    onClose={handleCloseAdd}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            updateNews(formJson, "add");
                            handleCloseAdd();
                        },
                    }}
                >
                    <DialogTitle>{localized.AddNews}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {localized.FillRequiredFieldsBelow}
                        </DialogContentText>
                        <TextField
                            inputProps={{ maxLength: 250 }}
                            required
                            margin="dense"
                            id="title"
                            name="title"
                            label={localized.Title}
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            required
                            margin="dense"
                            id="content"
                            name="content"
                            label={localized.Content}
                            type="text"
                            fullWidth
                            variant="standard"
                        />               
                        <TextField
                            inputProps={{ maxLength: 3 }}
                            required
                            margin="dense"
                            id="priority"
                            name="priority"
                            label={localized.Priority}
                            type="number"
                            fullWidth
                            variant="standard"
                        />
                        <FormLabel required sx={{ fontSize: 10 }}>{localized.PostDate + ":  "}</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                defaultValue={new Date()}
                                required
                                value={addDateValue}
                                onChange={() => true}
                                onAccept={(x) => handleDateChangeAdd(x)}
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAdd}>{localized.StorefrontAdminCancel}</Button>
                        <Button type="submit">{localized.StorefrontAdminAdd}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }    
    const columns = [
        { field: 'title', headerName: localized.Title, width: 100 },
        { field: 'content', headerName: localized.Content, width: 250 },
        { field: 'priority', headerName: localized.Priority, width: 50 },
        { field: 'lastModifiedBy', headerName: localized.LastModifiedBy, width: 200 },
        { field: 'postDate', headerName: localized.PostDate, width: 150 },
        { field: 'lastModifiedByDate', headerName: localized.LastModificationDate, width: 150 },
        {
            field: 'updateButton',
            headerName:  localized.StorefrontAdminUpdate,
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenUpdate(e, params.row)}
                        >
                            {localized.StorefrontAdminUpdate}
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true

        },
        {
            field: 'deleteButton',
            headerName: localized.StorefrontAdminDelete,
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={(e) => handleClickOpenDelete(e, params.row)}
                        >
                            {localized.StorefrontAdminDelete}
                        </Button>
                    </strong>
                )
            },
            disableClickEventBubbling: true
        }
    ];

    return (
        <>
            {
                fetching && <Loading type="brand" />
            }
            {
                !fetching && (localized && Object.keys(localized).length >0) && 
                <>
                    <h1>{localized.NewsAdministrationHeader}</h1>
                    <Paper sx={{ height: 700, width: '100%' }}>
                        <TranslatableDataGrid rows={rows} columns={columns} insetToolbar={customToolbar} labels={localized} />
                    </Paper>
                    {openUpdate && renderUpdateButton(selectedRow)}
                    {openDelete && renderDeleteButton(selectedRow)}
                </>
            }
        </>
    );
}

export default NewsAdministration;

