import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import { useHistory } from 'react-router-dom';
import Storefront from '../../services/Storefront';
import Loading from '../Loading';
export const AdminIndex = props => {
    
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = props.history || useHistory();
    const storeData = useStorefrontSettings(); //for labels eventually
    
    useEffect(() => {
            getAdministrationTypes();
    }, []);    

    const getAdministrationTypes = () => {
        setLoading(true);
        return Storefront.getAdministrationTypes(storeData.storefrontNumber).then((data) => {
            if (data) {
                setSections(data);
            }
            setLoading(false);
        });
    }

    const navigateToSection = (url) => {
        history.push({ pathname: url, state: {} });
    }

    return (
        <div className="fele-reporting">
            {loading && <Loading type="brand" />}
            {
                !loading &&
                <div className="row row-cols-1 row-cols-md-2 g-4" style={{ margin: '50px' }}>
                    {
                        sections.map((r, key) =>
                            <div className="col align-self-center" role="button" key={key} onClick={() => navigateToSection(r.path)} style={{ width: 400, marginRight: '50px' }}>
                                <div className="card bg-button-color" style={{ height: 100 }}>
                                    <div className="card-body text-center" style={{ margin: 0, position: 'absolute', top: '50%', left: '50%', msTransform: 'translate(-50%, -50%)', transform: 'translate(-50%, -50%)' }}>
                                        <h2 className="card-title display-5" style={{ whiteSpace: 'nowrap' }}><b>{r.name}</b></h2>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    );
}

export default AdminIndex;