import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Roles from '../resources/UserRole';
import ClaimService from '../services/ClaimService';
import Notifications from "../services/Notifications";
import Claims from './claims/Claims';
import NonFastTrackModal from './claims/NonFastTrackModal';
import ClaimsFilter from './claims/ClaimsFilter';
import ClaimsSubmission from './claims/ClaimsSubmission';
import Paging from './Paging';
import DateHelper from '../helpers/DateHelper';
import UserHelper from '../helpers/UserHelper';
import { withSelectedCustomer } from '../hooks/withSelectedCustomer';
import { withStorefrontConfig } from '../hooks/StorefrontSettingsContext';
import { withWarrantyConfig } from '../hooks/WarrantyConfigContext';
import { getState } from './account/AccountSummary';
import NotificationBanner from "./banner/NotificationBanner";

export const ClaimHistory = (props) => {
    const localized = props.WarrantyConfig.labels;
    const [status, setStatus] = useState("");
    const [statusCodes, setStatusCodes] = useState("");
    const [claimsStatus, setClaimsStatus] = useState("");
    const [claimStatusFilters, setClaimStatusFilters] = useState({ value: "", label: "" });

    const [fullName, setFullName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [customerNumber, setCustomerNumber] = useState("");
    const [branchPlant, setBranchPlant] = useState("");

    const [dateRange, setDateRange] = useState("30");
    const [statusDescription, setStatusDescription] = useState("");
    const [approvalStatus, setApprovalStatus] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);

    const [count, setCount] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [customerId, setCustomerId] = useState(props && props.selectedCustomer ? props.selectedCustomer.id : null);
    const [billToCustomer, setBillToCustomer] = useState(props && props.selectedCustomer ? props.selectedCustomer.billToId : null);
    const [storefrontId, setStorefrontId] = useState(props && props.currentUser.storefrontId);

    // Pending Claims added from form
    const [claims, setClaims] = useState(null);
    const [addingItem, setAddingItem] = useState(false);

    // State Passed To ClaimsSubmission Start
    const [claimLines, setClaimLines] = useState([]);
    const [returnPolicy, setReturnPolicy] = useState(false);
    const [obsolete, setObsolete] = useState(false);
    const [duplicateSerial, setDuplicateSerial] = useState(false);
    const [isPioneerItem, setIsPioneerItem] = useState(false);
    const [pioneerBrand, setPioneerBrand] = useState({});
    const [itemDispositions, setItemDispositions] = useState([]);
    const [productType, setProductType] = useState("");
    const [productDescription, setProductDescription] = useState("");

    // Validation
    const [isValidClaim, setIsValidClaim] = useState(false);
    const [claimApproval, setClaimApproval] = useState([]);

    // Errors
    const [serialNumberErrors, setSerialNumberErrors] = useState(false);
    const [claimTypeOptionError, setClaimTypeOptionError] = useState(false);
    const [referenceNumberError, setReferenceNumberError] = useState(false);
    const [itemNumberError, setItemNumberError] = useState(null);
    const [installDateError, setInstallDateError] = useState(false);
    const [failureDateError, setFailureDateError] = useState(false);
    const [loyalityIDWarning, setLoyalityIDWarning] = useState({ isNumber: true, loyaltyID: null });
    const [serialNumberWarning, setSerialNumberWarning] = useState(false);
    const [fastTrackError, setFastTrackError] = useState(false);
    const [illegitimateSerialNumber, setIllegitimateSerialNumber] = useState(false);
    const [hasWarrantyAllowanceError, setWarrantyAllowanceError] = useState(false);

    // Upload Files
    const [uploadFiles, setUploadFiles] = useState([]);

    // Input Form Values
    const [itemNumberSubmit, setItemNumberSubmit] = useState("");
    const [hasCustomerWarrantyAllowance, setHasCustomerWarrantyAllowance] = useState(false);
    const [serialNumberSubmit, setserialNumberSubmit] = useState("");
    const [loyaltyIDSubmit, setLoyaltyIDSubmit] = useState("");
    const [referenceNumberSubmit, setReferenceNumberSubmit] = useState("");
    const [claimType, setClaimType] = useState("");
    const [detailsCommentSubmit, setDetailsCommentSubmit] = useState("");
    const [dateFailedSubmit, setDateFailedSubmit] = useState("");
    const [dateInstalledSubmit, setDateInstalledSubmit] = useState("");
    const [pumpManufacturer, setPumpManufacturer] = useState("");
    const [failureType, setFailureType] = useState({ selected: "", isCommentsRequired: true });
    // State Passed To ClaimsSubmission End

    // Edit Mode
    const [isEditMode, setIsEditMode] = useState(false);
    const [serialNumberBeingEdited, setSerialNumberBeingEdited] = useState(null);

    const [canApproveRejectClaim, setCanApproveRejectClaim] = useState(
      props.currentUser.roles.includes(Roles.CAN_APPROVE_REJECT_CLAIM)
        ? true
        : false
    );
    const [showNonFastTrackModal, setShowNonFastTrackModal] = useState(false);
    const [allowReplacement, setAllowReplacement] = useState(false);
    const [serialNumberClassName, setSerialNumberClassName] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [allDispositions, setAllDispositions] = useState([]);
    const SDCNumbers = ["5870205313C","5870205403C","5870205503C"]
    const validationResponseMessages = {
        "GenericError": localized.GenericError,
        "InvalidSerialNumber": localized.InvalidSerialNumber,
        "MissingSerialNumber": localized.MissingSerialNumber,
        "MissingItemNumber": localized.MissingItemNumber,
        "HasWarrantyAllowance": localized.HasWarrantyAllowance
    }
    const getStates = () => {
        return {
            status, dateRange, pageSize, page, searchValue, claimsStatus, claimStatusFilters
            , statusDescription, claimType, approvalStatus, claims, count, fetching, customerId, billToCustomer, storefrontId, detailsCommentSubmit
            , itemNumberSubmit, serialNumberSubmit, dateInstalledSubmit, dateFailedSubmit, referenceNumberSubmit, loyaltyIDSubmit, itemNumberError, installDateError
            , failureDateError, fastTrackError, claimLines, claimApproval, canApproveRejectClaim, serialNumberWarning, loyalityIDWarning, showNonFastTrackModal, referenceNumberError
            , claimTypeOptionError, allowReplacement, returnPolicy, obsolete, duplicateSerial, serialNumberClassName, serialNumberErrors, isValidClaim, pioneerBrand, isPioneerItem
            , uploadFiles, itemDispositions, allDispositions
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (props?.selectedCustomer?.id) {
                setCustomerId(props?.selectedCustomer?.id);
                setBillToCustomer(props?.selectedCustomer?.billToId);
                resetChanges();

                await Promise.all([
                    fetchClaimsStatus(),
                    fetchStatusCodes(),
                    fetchClaimDispositions(),
                    fetchCustomerWarrantyAllowance()
                ]);
            }
        };

        fetchData();
    }, [props?.selectedCustomer?.id]);

    useEffect(() => {
        const fetch = async () => {
            await fetchClaims();
        };

        fetch();
    }, [customerId, status, page, dateRange, searchValue]);

    var hasFastTrackError = false;
    var keyDealerExists = null;
    let defaultMonths = 66;

    const loadDispositions = (response) => {
        if (response) {
            setAllDispositions(response);
        }
    };

    const fetchCustomerWarrantyAllowance = async () => {
        const hasWarrantyAllowance = await ClaimService.fetchCustomerWarrantyAllowance(props.selectedCustomer.billToId);
        setHasCustomerWarrantyAllowance(hasWarrantyAllowance);
    }

    const fetchClaimDispositions = async () => {
        if (props?.selectedCustomer) {
            try {
            const storefrontId_1 = storefrontId || props.currentUser.storefrontId;

                const dispositions = await ClaimService.fetchDispositions(storefrontId_1);

                loadDispositions(dispositions);
            } catch (error) {
                console.error("Error fetching claim dispositions:", error);
        }
        }
    };

    const setStatusCallback = (e) => {
        let status = null;
        status = e?.value;
        setStatus(status);
        setPage(1);
    };

    const setSearchValueCallback = (e) => {
        setSearchValue(e.target.value);
        setPage(1);
    };

    const setDateRangeCallback = (e) => {
        setDateRange(e.target.value);
        setPage(1);
    };

    const setClaimTypeCallback = (e) => {
        setClaimTypeOptionError(false);
        const selectedOption = {
            value: e.target.value,
            name: e.target.options[e.target.selectedIndex].text
        }
        setClaimType(selectedOption);
    };

    const setClaimCredit = (setCredit) => {
        if (setCredit) {
            setClaimType({ value: '1', name: 'Credit' });
        }
        else {
            setClaimType("");
        }
    };

    const setAnalysisOnlyClaimType = () => {
        setClaimType({ value: '3', name: 'Analysis Only' });
    };

    const setPioneerItem = (e) => {
        setIsPioneerItem(e);
    };

    const setItemNumberSubmitCallback = (itemNumber) => {
        setObsolete(false);
        setItemNumberSubmit(itemNumber);
    };

    const setSerialNumberSubmit = (e) => {
        setDuplicateSerial(false);
        if (UserHelper.checkSpecialCharacters(e.target.value)) {
            setSerialNumberErrors(true);
            setSerialNumberClassName("warning-textbox");
        }
        else {
            setserialNumberSubmit(e.target.value);
            setSerialNumberErrors(false);
            setSerialNumberClassName("");
        }
    };

    const setDuplicateSerialCallback = (isDuplicate) => {
        setDuplicateSerial(isDuplicate);
    };

    const setSerialNumberWarningCallback = (isWarning) => {
        setSerialNumberWarning(isWarning);
    };

    const checkAllAreDigits = (value, index) => {
        if (value.length === 0) return true;
        if (!index) index = 0;
        if (isNaN(value[index])) return false;
        if (index === value.length - 1) return true;
        return checkAllAreDigits(value, ++index);
    };

    const setLoyaltyIDSubmitCallback = (e => {
        if (checkAllAreDigits(e.target.value)) {
            setLoyalityIDWarning({ isNumber: true, loyaltyID: null });
            setLoyaltyIDSubmit(e.target.value);
        }
        else {
            setLoyalityIDWarning({ isNumber: false, loyaltyID: null });
        }

        keyDealerExists = false;
    });

    const setDateInstalledSubmitCallback = (date) => {
        let dateFormat = props.StorefrontConfig.settingsList.DateFormat ? props.StorefrontConfig.settingsList.DateFormat : "MM/dd/yyyy";
        setDateInstalledSubmit(DateHelper.format(date, dateFormat));
    };

    const setDateFailedSubmitCallback = (date) => {
        let dateFormat = props.StorefrontConfig.settingsList.DateFormat ? props.StorefrontConfig.settingsList.DateFormat : "MM/dd/yyyy";
        setDateFailedSubmit(DateHelper.format(date, dateFormat));
    };

    const setReferenceNumberSubmitCallback = (e) => {
        setReferenceNumberSubmit(e.target.value);
    };

    const pageCount = () => {
        return Math.ceil(count / pageSize);
    };

    const nextPage = () => {
        if (page < pageCount()) {
            setPage(page + 1);
        }
    };

    const previousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const fetchClaims = async () => {
        if (!props.selectedCustomer) {
            return;
        }

        try {
            setFetching(true);
            const claimsData = await ClaimService.fetchClaims(getStates(), props);
            loaded(claimsData);
        } catch (error) {
            console.error("Error fetching claims:", error);
        } finally {
            setFetching(false);
        }
    };

    const fetchStatusCodes = async () => {
        if (props.selectedCustomer) {
            try {
                const statusCodes = await ClaimService.fetchStatusCodes();
                loadStatusCodes(statusCodes);
            } catch (error) {
                console.error("Error fetching status codes:", error);
        }
        }
    };

    const loadStatusCodes = (response) => {
        if (response)
            setStatusCodes(response);
    };

    const fetchClaimsStatus = async () => {
        if (props.selectedCustomer) {
            try {
            const storefrontId_1 = storefrontId || props.currentUser.storefrontId;
                const claimsStatus = await ClaimService.fetchClaimsStatus(storefrontId_1);
                loadClaimsStatus(claimsStatus);
            } catch (error) {
                console.error("Error fetching claims status:", error);
        }
        }
    };

    const loadClaimsStatus = (response) => {
        if (response) {
            setClaimsStatus(response.warrantyClaimStatus);
        }
    };

    const loaded = (data) => {
        if (data) {
            setFetching(false);
            if (status) {
                let filteredClaims = data.warrantyClaims.filter(o => o.statusDescription === status);
                setClaims(filteredClaims);
                setCount(filteredClaims.length);
            }
            else {
                setClaims(data.warrantyClaims);
                setCount(data.totalCount);

            }
        }
        let statusResult = [];
        const filterStatus = data.warrantyClaims.map((o) => o.statusDescription).filter((v, i, a) => a.indexOf(v) === i);
        filterStatus.forEach((o) => {
            statusResult.push({ value: o, label: o });
        });

        setClaimStatusFilters(statusResult);

    };

    const addClaimItem = async () => {
        setAddingItem(true);

        const valid = await validateSubmission();
        if (valid) {
            saveClaimItem();
        }

        setAddingItem(false);
        return valid;
    };

    const editClaimItem = async () => {
        try {
            if (!isEditMode) {
                return;
            }

            setAddingItem(true);
            const isValid = await validateSubmission();

            if (!isValid) {
                setAddingItem(false);

                return false;
            }

            removeClaimItem(serialNumberBeingEdited);
            saveClaimItem();
            setAddingItem(false);

            return true;
        } catch (error) {
            console.error('Error editing claim item:', error);

            return false;
        }
    };

    const clearForm = () => {
        setDetailsCommentSubmit("");
        setItemNumberSubmit("");
        setserialNumberSubmit("");
        setDateFailedSubmit(null);
        setDateInstalledSubmit(null);
        setReferenceNumberSubmit("");
        setLoyaltyIDSubmit("");
        setFastTrackError("");
        setClaimType("");
        setObsolete(false);
        setPioneerBrand("");
        setIsPioneerItem(false);
        setReturnPolicy("");
        setUploadFiles([]);
        setFullName("");
        setEmailAddress("");
        setCustomerNumber("");
        setBranchPlant("");
        setSerialNumberBeingEdited(null);
        setIsEditMode(false);
        setPumpManufacturer("");
        setFailureType({ selected: "", isCommentsRequired: true });
        setProductDescription("");
        keyDealerExists = null;
        defaultMonths = 66;
    };

    const validateFastTrack = async (itemNumber) => {
        try {
            setWarrantyAllowanceError(false);
            setFetching(true);
            const data = await ClaimService.fetchFastTrackStatus(itemNumber, props.selectedCustomer.billToId);            
            if (data && data.itemNotFound) {
                Notifications.error(localized.ItemNotFound);
            }
            
           
            setItemNumberError(data?.itemNotFound || false);
            setReturnPolicy(data.returnPolicy);
            setObsolete(data.obsolete);
            setPioneerBrand(data.pioneerBrand);
            setIsPioneerItem(data.pioneerBrand && data.pioneerBrand.shown == true ? true : false);
            setDispositions(data);
            setProductType(data.productType);
            setProductDescription(data.productDescription);

            if (data?.productType !== "Motor") {
                setPumpManufacturer("");
            }
            if (data && hasCustomerWarrantyAllowance && data.returnPolicy == 'F' && !SDCNumbers.includes(itemNumber.toUpperCase())) {
                Notifications.error(validationResponseMessages["HasWarrantyAllowance"]);
                setWarrantyAllowanceError(true);
                setFetching(false);
                return
            }            
        } catch (error) {
            console.error('Error validating fast track:', error);
        }
        setFetching(false);
    };

    const checkIfItemNumberIsEligibleForValidation = async (itemNumber) => {
        try {
            const response = await ClaimService.checkIfItemNeedsValidation(itemNumber);

            return response;
        } catch (error) {
            console.error("Error checking if item number needs validation:", error);

            return false;
        }
    };

    const bypassFastTrackValidation = async (itemNumber,serialNumber) => {
        try {
            setWarrantyAllowanceError(false);
            var returnPolicyBypass;
            var billTo = props.selectedCustomer.billToId;
            const data = await ClaimService.fetchFastTrackStatus(itemNumber,billTo);
            const serialYear = serialNumber.slice(0, 2);
            const serialMonth = serialNumber.slice(2, 3);
            const prior = ["A", "B", "C"];
            if ((serialYear == 24 && !prior.includes(serialMonth.toUpperCase())) || serialYear > 24) {
                returnPolicyBypass = ' ';                
            }
            else {
                if (data && hasCustomerWarrantyAllowance && serialNumber.length >= 3) {
                    Notifications.error(validationResponseMessages["HasWarrantyAllowance"]);
                    setWarrantyAllowanceError(true);                    
                } 
                returnPolicyBypass = "F";
            }
            setReturnPolicy(returnPolicyBypass);
            setObsolete(data.obsolete);
            setPioneerBrand(data.pioneerBrand);
            setIsPioneerItem(data.pioneerBrand && data.pioneerBrand.shown == true ? true : false);
            data.returnPolicy = returnPolicyBypass;
            setDispositions(data);
            setProductType(data.productType);

            if (data?.productType !== "Motor") {
                setPumpManufacturer("");
            }
            
            
        } catch (error) {
            console.error('Error bypassing fast track validation:', error);
        }
    };

    const setDispositions = (data) => {
        try {
            const filterDispositions = allDispositions.warrantyClaimDisposition.filter(dt => dt.returnPolicy == data.returnPolicy);
            var comparator = data.sku || data.itemNumber;
            if (data.returnPolicy == ' ') {
                if (hasCustomerWarrantyAllowance && SDCNumbers.includes(comparator.toUpperCase())) {
                    setItemDispositions(filterDispositions.filter(al => al.id == 4));// Analysis only based on role)
                }
                else {
                    if (canApproveRejectClaim === false) {
                        setItemDispositions(filterDispositions.filter(al => al.id != 4));
                    }
                    else {
                        setItemDispositions(filterDispositions);
                    }
                }
                    // Analysis only based on role
            }
            else {
                setItemDispositions(filterDispositions);
            }
        } catch (error) {
            console.error("An error occurred while filtering dispositions:", error);
            Notifications.error(localized.DispositionError);
        }
    };

    const checkFastTrackValidity = async (serialNumberUnformatted, itemNumber, checkSerial) => {
        let hasError = false;
        if (checkSerial) {
            const cleanSerialNumber = serialNumberUnformatted.replace(/[^0-9a-zA-Z]/g, '').trim();
            const monthLetters = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, J: 9, K: 10, L: 11, M: 12 };
            

            const setError = (messageKey) => {
                Notifications.error(props.WarrantyConfig.labels[messageKey]);
                setFastTrackError(true);
                hasFastTrackError = hasError = true
            };

            if (cleanSerialNumber.length > 13) {
                setError('SerialFastTrackLengthError');

                return;
            }

            const serialPattern = /^\d{2}([ABCDEFGHJKLMabcdefghjklm]){1}([A-Za-z0-9]){2}[0-3]\d.{5}[A-Za-z0-9]$/;
            if (!serialPattern.test(cleanSerialNumber)) {
                setError('SerialFastTrackFormatError');

                return;
            }

            const { warrantyMonths, keyDealerExists: loyaltyID } = await ClaimService.getWarrantyMonths(itemNumber.trim(), loyaltyIDSubmit.trim());
            setLoyalityIDWarning({ ...loyalityIDWarning, loyaltyID });
            keyDealerExists = loyaltyID
            defaultMonths = warrantyMonths;

            const serialYear = parseInt(cleanSerialNumber.substring(0, 2), 10);
            const serialMonthChar = cleanSerialNumber.charAt(2).toUpperCase();
            const serialDay = parseInt(cleanSerialNumber.substring(5, 7), 10);
            const serialMonth = monthLetters[serialMonthChar];

            if (!serialMonth || serialYear <= 0 || serialDay < 1 || serialDay > 31) {
                setError('SerialFastTrackFormatError');

                return;
            }

            const currentYear = new Date().getFullYear();
            const currentCentury = Math.floor(currentYear / 100);
            const serialDate = new Date(`${currentCentury}${serialYear}`, serialMonth - 1, serialDay);

            const currentDate = new Date();

            if (serialDate > currentDate) {
                setError('SerialFutureError');

                return;
            }

            const warrantyCutoffDate = new Date(serialDate);
            warrantyCutoffDate.setMonth(warrantyCutoffDate.getMonth() + warrantyMonths);

            if (currentDate > warrantyCutoffDate) {
                Notifications.errorLong(props.WarrantyConfig.labels.SerialFastTrackAgeError);
                setFastTrackError(true);
                hasFastTrackError = hasError = true;

                return;
            }            
        }

        const validationResponse = await ClaimService.checkValidity(itemNumberSubmit.trim(),
            checkSerial,
            serialNumberSubmit.trim(),
            props.selectedCustomer.billToId);


        if (validationResponse == "InvalidSerialNumber") {
            Notifications.error(validationResponseMessages[validationResponse]);
            setIllegitimateSerialNumber(true);
            hasFastTrackError = hasError = true;
            return;
        }
        if (validationResponse == "ValidSerialNumber") {
            Notifications.message(validationResponseMessages[validationResponse]);
            setIllegitimateSerialNumber(false);
            hasFastTrackError = hasError = false;        
        }
        setFastTrackError(hasError);
        hasFastTrackError = hasError;
    };

    const checkSerialNumber = async (itemNumber) => {
        var serialNumber = serialNumberSubmit.trim();
        const isEligibleForValidation = await checkIfItemNumberIsEligibleForValidation(itemNumber);

        //if an item is fast track, make sure the length is no greater than 13 characters
        if (returnPolicy === 'F' && props.WarrantyConfig.settings.ShowSerialNumberAlert) {
            if (isEligibleForValidation) {
                await checkFastTrackValidity(serialNumber, itemNumber, true);
            }
            else {
                await checkFastTrackValidity(serialNumber, itemNumber, false);
            }
        }
            

        // No duplicate serial numbers check for pioneer items. They comes in packing might have same serial numbers for multiple claims.
        if (serialNumber != "" && !isPioneerItem && !hasFastTrackError) {
            return await ClaimService.fetchWarrantySerialNumber(serialNumber)
                .then(data => {
                    if (data !== null) {
                        setSerialNumberClassName("unavailable");
                        setSerialNumberWarning(true);
                        Notifications.error(props.WarrantyConfig.labels.DuplicateSerialNumber);
                    }
                    else {
                        setSerialNumberClassName("");
                        setSerialNumberWarning(false);
                    }
                    return data;
                });
        } else {
            setSerialNumberErrors(serialNumber === "");
            setSerialNumberClassName("unavailable");
        }
    };

    const validateInstallFailureDate = () => {
        if (isPioneerItem) {
            return true;
        }

        const installDate = dateInstalledSubmit;
        const failureDate = dateFailedSubmit;

        if (!installDate || !failureDate) {
            return false;
        }

        const currentDate = new Date();
        const cutoffDate = DateHelper.subtract(currentDate, { months: 66 });
        const compareInstallDate = DateHelper.checkDateIsString(installDate, props.StorefrontConfig.settingsList.dateFormat);
        const compareFailureDate = DateHelper.checkDateIsString(failureDate, props.StorefrontConfig.settingsList.dateFormat);

        // Install Date can't be in the future
        if (DateHelper.compareDates(compareInstallDate, currentDate) === 1) {
            Notifications.error(localized.DateInTheFuture);
            setInstallDateError(true);

            return false;
        }

        // Failure Date can't be in the future
        if (DateHelper.compareDates(compareFailureDate, currentDate) === 1) {
            Notifications.error(localized.DateInTheFuture);
            setFailureDateError(true);

            return false;
        }

        // Install Date should be within the warranty period (cutoff date)
        if (DateHelper.compareDates(cutoffDate, compareInstallDate) === 1) {
            Notifications.error(localized.OutsideReturnPeriodError);
            setInstallDateError(true);

            return false;
        }

        // Failure Date should be on or after Install Date
        if (DateHelper.compareDates(compareInstallDate, compareFailureDate) === 1) {
            Notifications.error(localized.FailureDateBeforeInstallDate);
            setFailureDateError(true);

            return false;
        }

        setInstallDateError(false);
        setFailureDateError(false);
        return true;
    };

    const bypassDuplicateSerial = () => {
        if (!isEditMode) {
            return true;
        }

        const serialNumberIdentifier = serialNumberBeingEdited.split('-')[0];

        return !(serialNumberIdentifier === serialNumberSubmit);
    };

    const validateSubmission = async () => {
        // Valid Item Number is required
        var itemNumber = itemNumberSubmit.trim();
        var validData = true;

        // check for valid serial number
        const validSerialNumber = await checkSerialNumber(itemNumber);
        if (hasWarrantyAllowanceError) {
            Notifications.error(validationResponseMessages["HasWarrantyAllowance"]);
            return false;
        }
        if (hasFastTrackError) {
            return false;
        }

        let serialNumber = serialNumberSubmit.trim();
        if (validSerialNumber != null) {
            validData = false;
        }

        if (claimLines.filter(o => o.serialNumber === serialNumber).length > 0 && bypassDuplicateSerial()) {
            setSerialNumberClassName("unavailable");
            setSerialNumberWarning(true);
            Notifications.error(props.WarrantyConfig.labels.DuplicateSerialNumber);
            validData = false;
        }

        const validDate = validateInstallFailureDate();
        if (!validDate) {
            validData = false;

            return false;
        }

        if (keyDealerExists === false) {
            validData = false;

            return false;
        }

        return ClaimService.fetchWarrantyItemStatus(itemNumber.trim())
            .then(data => {
                if (data.sku === "" || data.sku === null) {
                    itemNumber = "";
                    validData = false;
                    Notifications.error("Invalid Item Number Entered");
                }
                setItemNumberError(itemNumber === "");

                // reference number is required
                let referenceNumber = referenceNumberSubmit.trim();
                setReferenceNumberError(referenceNumber === "");

                setClaimTypeOptionError(claimType === "");

                const validAdditonalComments = (failureType.isCommentsRequired && (detailsCommentSubmit == null || detailsCommentSubmit.trim() === ""));

                // pioneer warranty validation rules
                if (isPioneerItem === true) {
                    if (pioneerBrand && pioneerBrand.required == true) {
                        return !(
                            itemNumber === "" ||
                            referenceNumber === "" ||
                            serialNumber === "" ||
                            failureType.selected === "" ||
                            validAdditonalComments);
                    }
                    else {
                        return !(
                            itemNumber === "" ||
                            referenceNumber === "" ||
                            failureType.selected === "" ||
                            validAdditonalComments);
                    }
                }

                var isValid = !(
                    itemNumber === "" ||
                    claimType === "" ||
                    referenceNumber === "" ||
                    serialNumber === "" ||
                    failureType.selected === "" ||
                    validAdditonalComments) && validData;

                return isValid;
            });
    };

    const validatePioneerItem = (itemNumber, comments, referenceNumber, serialNumber) => {
        if (pioneerBrand && pioneerBrand.shown === true && pioneerBrand.required == true) {
            var isValidPioneerSnoItem = !(itemNumber === "" || comments === "" || referenceNumber === "" || serialNumber === "");
            return isValidPioneerSnoItem;
        }
        else {
            var isValidPioneerItem = !(itemNumber === "" || comments === "" || referenceNumber === "");
            return isValidPioneerItem;
        }
    };

    const removeClaimItem = (key) => {
        const updatedClaimLines = [...claimLines];
        const index = updatedClaimLines.findIndex(o => o.key === key);
        updatedClaimLines.splice(index, 1);

        // Clearing off the duplicate serial numbers validation message on claim submission.
        const validClaimLine = updatedClaimLines.filter((o) => o.isValid === false);
        if (validClaimLine?.length === 0) {
          setIsValidClaim(false);
        }

        setClaimLines(updatedClaimLines);
  };

    const loadClaimLineItemForEdit  = (key) => {
        setIsEditMode(true);
        setAddingItem(false);

        // Find the claim line to edit
        const index = claimLines.findIndex(o => o.key === key);
        const claimLine = claimLines[index];
        setSerialNumberBeingEdited(key);

        // Fill the form with the claim line data
        setDetailsCommentSubmit(claimLine.detailsComments);
        setItemNumberSubmit(claimLine.itemNumber);
        setserialNumberSubmit(claimLine.serialNumber);
        setDateInstalledSubmit(new Date(claimLine.dateInstalled));
        setDateFailedSubmit(new Date(claimLine.dateFailed));
        setReferenceNumberSubmit(claimLine.referenceNumber);
        setLoyaltyIDSubmit(claimLine.loyaltyID);
        setClaimType(claimLine.claimType);
        setObsolete(claimLine.obsolete);
        setPioneerBrand(claimLine.pioneerBrand);
        setIsPioneerItem(claimLine.isPioneerItem);
        setReturnPolicy(claimLine.returnPolicy);
        setUploadFiles([...claimLine.uploadFiles]);
        setFullName(claimLine.fullName);
        setEmailAddress(claimLine.emailAddress);
        setCustomerNumber(claimLine.customerNumber);
        setBranchPlant(claimLine.branchPlant);
        setPumpManufacturer(claimLine.pumpManufacturer);
        setFailureType({ selected: claimLine.failureType, isCommentsRequired: claimLine.isCommentsRequired });

        // Load dispositions
        setDispositions(claimLine);
    };

    const setShowModal = showModal => {
        setShowNonFastTrackModal(showModal);
    };

    const submitClaim = () => {
        let nonFastTrackClaims = claimLines.filter(item => item.fastTrack !== true);
        if (nonFastTrackClaims.length > 0) {
            setShowModal(true);
        }
        else executeSubmit();
    };

    const executeSubmit = async (nftClaims) => {
        let fastTrackClaims = claimLines.filter(item => item.fastTrack === true);
        let nonFastTrackClaims = nftClaims ? nftClaims : claimLines.filter(item => item.fastTrack !== true);
        setFetching(true);
        setShowNonFastTrackModal(false);

        // Validate serial numbers on claim submission.
        let validatedClaimLines = await validateClaim(fastTrackClaims, nonFastTrackClaims).then(data => {
            if (data) {
                return data;
            }
        });

        // Submit claims only if all the items are valid(no duplicates)
        let valid = validatedClaimLines?.filter(o => o.isValid == false);
        if (valid?.length === 0) {
            setIsValidClaim(false);
            submitClaimToAPI([...fastTrackClaims, ...nonFastTrackClaims]);
        }
        else {
            setFetching(false);
            setIsValidClaim(true);
            setClaimLines(validatedClaimLines);
            Notifications.errorShow(localized.DuplicateClaimSubmission);
        }
    };

    const validateClaim = async (fastTrackClaims, nonFastTrackClaims) => {
        return await ClaimService.validateClaim([...fastTrackClaims, ...nonFastTrackClaims]).then(response => {
            if (response) {
                return response;
            }
        });
    };

    const claimFailureResponse = () => {
        setFetching(false);
        Notifications.errorShow(localized.ClaimSubmitError);
    };

    const claimSuccessResponse = () => {
        Notifications.message(localized.ClaimSubmitted);
        fetchClaims();
    };

    const uploadClaimAttachments = (files, claimLine) => {
        const claimsAttachmentsToUpload = claimLines.map(claim => {
            if (claim === claimLine) {
                return {
                    ...claim,
                    uploadFiles: [...files]
                }
            }
            else {
                return {
                    ...claim
                }
            }
        });
        setClaimLines(claimsAttachmentsToUpload);
    };

    const uploadClaimAttachmentsToLocalClaimItem = (files) => {
      setUploadFiles([...files]);
    };

    const submitClaimWithoutAttachmentsToAPI = async (claimsWithoutAttachments) => {
        if (claimsWithoutAttachments.length > 0) {
            try {
                const res = await ClaimService.submitClaim(claimsWithoutAttachments);
                if (res) {
                    Notifications.message(localized.ClaimSubmitted);
                    await fetchClaims();
                    setClaimLines([]); // TODO: handle failure scenarios
                } else {
                    claimFailureResponse();
                }
            } catch (error) {
                console.error("Error submitting claims: ", error);
                claimFailureResponse();
            }
        }
    };

    const submitClaimWithAttachmentsToAPI = (claimsWithAttachments) => {
        const claimsWithoutAttachments = claimLines.filter(item => item.uploadFiles?.length == 0);
        if (claimsWithAttachments.length > 0) {
            claimsWithAttachments.map(cl => {
                ClaimService.submitClaimWithAttachment(cl).then(res => {
                    if (res !== undefined) {
                        if (claimsWithoutAttachments.length == 0) { // This check because to avoid confusion to user multiple toasts for combination of claims
                            claimSuccessResponse();
                            let removeClaimLine = claimsWithAttachments.filter(o => o.serialNumber != cl.serialNumber);
                            setClaimLines(removeClaimLine);
                        }
                        // Upload attachments to azure and send to E1.
                        ClaimService.uploadAttachment(cl.uploadFiles, res["Case Number"], cl.customerNumber).then(response => {
                            if (response) {
                                Notifications.message(localized.AttachmentSubmitted);
                            }
                            else {
                                Notifications.error(localized.AttachmentError);
                            }
                        })
                    } else {
                        if (claimsWithoutAttachments.length == 0) {
                            claimFailureResponse();
                        }
                    }
                });
            });
        }
    };

    const submitClaimToAPI = (claimLines) => {
        let claimsWithAttachments = claimLines.filter(item => item.uploadFiles?.length > 0);
        let claimsWithoutAttachments = claimLines.filter(item => item.uploadFiles?.length == 0);
        submitClaimWithAttachmentsToAPI(claimsWithAttachments);
        submitClaimWithoutAttachmentsToAPI(claimsWithoutAttachments);
    };

    const handleEnter = (e) => {
        if (e.key === "Enter")
            addClaimItem();
    };

    const setClaimApprovalCallback = (caseNumber, e) => {
        const claimsApprovalData = claims.map(claim => {
            if (claim.caseNumber === caseNumber) {
                return {
                    ...claim,
                    approvalStatus: e.target.value
                }
            }
            else {
                return {
                    ...claim
                }
            }
        });

        setClaims(claimsApprovalData);
        const filterData = claimsApprovalData.filter(o => o.approvalStatus);
        setClaimApproval(filterData);
    };

    const saveChangesClaimApproval = async () => {
        const approvalClaims = claimApproval;

        if (canApproveRejectClaim && approvalClaims.length > 0) {
            setFetching(true);

            for (const claim of approvalClaims) {
                const data = {
                    caseNumber: claim.caseNumber.toString(),
                    approved: claim.approvalStatus === "1" ? "true" : "false",
                    fileName: `${claim.billToCustomer}-${claim.rmA_PONumber}${claim.documentType}`,
                    customerId: customerId,
                    fullName: `${props.currentUser.firstName} ${props.currentUser.lastName}`,
                    emailAddress: props.currentUser.email
                };

                try {
                    const res = await ClaimService.claimApproval(data, customerId);
                        if (res) {
                            data.approved === "true"
                                ? Notifications.message(localized.ClaimApproved)
                                : Notifications.message(localized.ClaimRejected);
                        } else {
                            Notifications.error(localized.ClaimApprovalError);
                        }
                } catch (error) {
                    Notifications.error(localized.ClaimApprovalError);
                }
            }

            await fetchClaims();
        }
    };

    const resetChanges = () => {
        clearForm();
        setClaimLines([]);
        setClaimApproval([]);
        if (claims?.length > 0) {
            const claimsApprovalData = claims.map(claim => {
                return {
                    ...claim,
                    approvalStatus: ''
                }
            });
            setClaims(claimsApprovalData);
        }
    };

    const saveClaimItem = () => {
        var lineNumber;
        var fastTrackValid = returnPolicy;
        if (fastTrackValid === 'F') {
            let fastTrackClaimLines = claimLines.filter(item => item.fastTrack === true && item.claimType.value === "1");// Credit
            var newLineIndex = fastTrackClaimLines.length + 1;
            lineNumber = newLineIndex.toFixed(2);
        }

        if (hasFastTrackError == false) {
            let newClaimLine = {                
                comments: failureType.selected, // Note: Has maximum character length 25 from previous logic
                detailsComments: detailsCommentSubmit,
                itemNumber: itemNumberSubmit,
                serialNumber: serialNumberSubmit,
                dateInstalled: dateInstalledSubmit,
                dateFailed: dateFailedSubmit,
                referenceNumber: referenceNumberSubmit,
                loyaltyID: loyaltyIDSubmit,
                rmaLineNumber: lineNumber,
                fastTrack: (fastTrackValid === 'F'),
                claimType: claimType,
                fullName: props.currentUser.firstName + ' ' + props.currentUser.lastName,
                emailAddress: props.currentUser.email,
                customerNumber: customerId,
                billToCustomer: billToCustomer,
                branchPlant: props.currentUser.storefrontNumber,
                returnConfirmation: true,
                obsolete: obsolete,
                pioneerBrand: pioneerBrand,
                isPioneerItem: isPioneerItem,
                uploadFiles: uploadFiles,
                returnPolicy: returnPolicy,
                pumpManufacturer: pumpManufacturer,
                failureType: failureType.selected,
                isCommentsRequired: failureType.isCommentsRequired,
                key: serialNumberSubmit + - + new Date().getTime(),
            };

            setClaimLines((prevState) => ([...prevState, newClaimLine]));
            clearForm();
        }
    }

    const renderClaimSubmission = () => {
        return (
            <ClaimsSubmission
                SDCNumbers={SDCNumbers}
                setDetailsCommentSubmit={setDetailsCommentSubmit}
                detailsCommentSubmit={detailsCommentSubmit}
                setItemNumberSubmit={setItemNumberSubmitCallback}
                setSerialNumberSubmit={setSerialNumberSubmit}
                setDateInstalledSubmit={setDateInstalledSubmitCallback}
                setDateFailedSubmit={setDateFailedSubmitCallback}
                setReferenceNumberSubmit={setReferenceNumberSubmitCallback}
                setLoyaltyIDSubmit={setLoyaltyIDSubmitCallback}
                setSerialNumberWarning={setSerialNumberWarningCallback}
                setClaimCredit={setClaimCredit}
                setDuplicateSerial={setDuplicateSerialCallback}
                itemNumberSubmit={itemNumberSubmit}
                serialNumberSubmit={serialNumberSubmit}
                dateInstalledSubmit={dateInstalledSubmit}
                dateFailedSubmit={dateFailedSubmit}
                referenceNumberSubmit={referenceNumberSubmit}
                loyaltyIDSubmit={loyaltyIDSubmit}
                removeClaimItem={removeClaimItem}
                uploadFiles={uploadFiles}
                uploadClaimAttachments={uploadClaimAttachments}
                uploadClaimAttachmentsToLocalClaimItem={
                    uploadClaimAttachmentsToLocalClaimItem
                }
                addClaimItem={addClaimItem}
                addingItem={addingItem}
                validateFastTrack={validateFastTrack}
                checkIfItemNumberIsEligibleForValidation={checkIfItemNumberIsEligibleForValidation}
                bypassFastTrackValidation={bypassFastTrackValidation}
                submitClaim={submitClaim}
                claimLines={claimLines}
                handleEnter={handleEnter}
                dateFormat={props.StorefrontConfig.settingsList.dateFormat}
                itemNumberError={itemNumberError}
                installDateError={installDateError}
                failureDateError={failureDateError}
                serialNumberWarning={serialNumberWarning}
                loyalityIDWarning={loyalityIDWarning}
                referenceNumberError={referenceNumberError}
                setClaimType={setClaimTypeCallback}
                claimType={claimType}
                claimTypeOptionError={claimTypeOptionError}
                serialNumberClassName={serialNumberClassName}
                returnPolicy={returnPolicy}
                obsolete={obsolete}
                duplicateSerial={duplicateSerial}
                claims={claims}
                serialNumberErrors={serialNumberErrors}
                fetching={fetching}
                canApproveRejectClaim={canApproveRejectClaim}
                isValidClaim={isValidClaim}
                pioneerBrand={pioneerBrand}
                isPioneerItem={isPioneerItem}
                setAnalysisOnlyClaimType={setAnalysisOnlyClaimType}
                setPioneerItem={setPioneerItem}
                itemDispositions={itemDispositions}
                locale={props.StorefrontConfig.locale}
                loadClaimLineItemForEdit={loadClaimLineItemForEdit}
                isEditMode={isEditMode}
                editClaimItem={editClaimItem}
                clearForm={clearForm}
                productType={productType}
                pumpManufacturer={pumpManufacturer}
                setPumpManufacturer={setPumpManufacturer}
                failureType={failureType}
                setFailureType={setFailureType}
                productDescription={productDescription}
                illegitimateSerialNumber={illegitimateSerialNumber}
                serialNumberBeingEdited={serialNumberBeingEdited}
            />);
    };

    const renderClaimFilter = () => {
        return (
            <ClaimsFilter
                dateRange={dateRange}
                statusFilter={status}
                setDateRange={setDateRangeCallback}
                setStatus={setStatusCallback}
                fetchClaims={fetchClaims}
                claims={claims}
                canApproveRejectClaim={canApproveRejectClaim}
                saveChangesClaimApproval={saveChangesClaimApproval}
                resetChanges={resetChanges}
                claimStatusFilters={claimStatusFilters}
                searchValue={searchValue}
                setSearchValue={setSearchValueCallback}
                fetching={fetching}
            />);
    };

    const renderClaims = () => {
        const hasSubmit = props.currentUser.roles.includes(Roles.SUBMIT_CLAIM);
        const hasShipToOrBothAddressType = UserHelper.checkCustomerAddressType(props.selectedCustomer.addressType);
        return (
            <div>
                {
                    hasSubmit && hasShipToOrBothAddressType && renderClaimSubmission()
                }
                {
                    <div>
                        {!hasShipToOrBothAddressType && (
                            <NotificationBanner
                                classOverride="mb-4"
                                page="ClaimHistory"
                                locale={props.StorefrontConfig.locale}
                            />
                        )}

                        {renderClaimFilter()}
                    </div>
                }

                <Claims
                    claims={claims}
                    fetching={fetching}
                    renderPaging={renderPaging}
                    approvalStatus={approvalStatus}
                    setClaimApproval={setClaimApprovalCallback}
                    statusCodes={statusCodes}
                    claimsStatus={claimsStatus}
                    roles={props.currentUser.roles}
                />
            </div>
        );
    };

    const renderPaging = () => {
        return (
            <Paging activePage={page} pageCount={pageCount} nextPage={nextPage} previousPage={previousPage} />
        );
    };

    const { selectedCustomer } = props;
    return (
        <React.Fragment>
            {showNonFastTrackModal &&
                <NonFastTrackModal setShowModal={setShowModal} claims={claimLines} addNonFastTrackFields={executeSubmit} ></NonFastTrackModal>
            }
            {selectedCustomer &&
                <React.Fragment>
                    {
                        renderClaims()
                    }
                </React.Fragment>
            }
        </React.Fragment>
    );

};

ClaimHistory.propTypes = {
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
    dateFormat: PropTypes.string
}

function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
        currentUser: state.user.currentUser,
    };
}

export default connect(
    mapStateToProps,
    null
)(withWarrantyConfig(withStorefrontConfig(withSelectedCustomer(ClaimHistory))));