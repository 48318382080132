import * as React from 'react';
import { useState, useEffect } from 'react';
import { useStorefrontSettings } from './StorefrontSettingsContext';
import Localization from '../services/Localization';
import * as Components from '../resources/Components';

const StorefrontAdministrationConfigContext = React.createContext();

function StorefrontAdministrationConfigProvider({ children, ...props }) {
    const [labels, setLabels] = useState({});
    const { locale } = useStorefrontSettings();
    const storefrontNumber = ('00000' + props.storefrontNumber).slice(-5);

    useEffect(() => {
        Localization.getComponentLabels(Components.STOREFRONTADMINISTRATION, locale, storefrontNumber)
            .then(res => {
                setLabels(res);
            })
    }, [locale]);

    return (
        <StorefrontAdministrationConfigContext.Provider
            value={{
                labels
            }}
        >
            {children}
        </StorefrontAdministrationConfigContext.Provider >
    );
}

function useStorefrontAdministrationConfig() {
    const context = React.useContext(StorefrontAdministrationConfigContext);
    if (context === undefined) {
        throw new Error('StorefrontAdministrationConfigContext must be used within a StorefrontAdministrationConfigContext');
    }
    return context;
}

function withStorefrontAdministrationConfig(Component) {
    return function WrappedComponent(props) {
        const StorefrontAdministrationConfig = useStorefrontAdministrationConfig();
        return <Component {...props}
            StorefrontAdministrationConfig={StorefrontAdministrationConfig}
        />;
    }
}

export { StorefrontAdministrationConfigProvider, useStorefrontAdministrationConfig, withStorefrontAdministrationConfig }