import { DataGrid } from '@mui/x-data-grid'; 

export const TranslatableDataGrid = (props) => {
    let labels = props.labels;
    return (  
        <>
            {(labels && labels.length>0) ?
                <DataGrid
                    localeText={{
                        noRowsLabel: labels.noRowsLabelMUI ?? "No rows",
                        noResultsOverlayLabel: labels.noResultsOverlayLabelMUI,
                        errorOverlayDefaultLabel: labels.errorOverlayDefaultLabelMUI,

                        // Density selector toolbar button text
                        toolbarDensity: labels.toolbarDensityMUI,
                        toolbarDensityLabel: labels.toolbarDensityLabelMUI,
                        toolbarDensityCompact: labels.toolbarDensityCompactMUI,
                        toolbarDensityStandard: labels.toolbarDensityStandardMUI,
                        toolbarDensityComfortable: labels.toolbarDensityComfortableMUI,

                        // Columns selector toolbar button text
                        toolbarColumns: labels.toolbarColumnsMUI,
                        toolbarColumnsLabel: labels.toolbarColumnsLabelMUI,

                        // Filters toolbar button text
                        toolbarFilters: labels.toolbarFiltersMUI,
                        toolbarFiltersLabel: labels.toolbarFiltersLabelMUI,
                        toolbarFiltersTooltipHide: labels.toolbarFiltersTooltipHideMUI,
                        toolbarFiltersTooltipShow: labels.toolbarFiltersTooltipShowMUI,
                        toolbarFiltersTooltipActive: (count) =>
                            count !== 1 ? `${count} ${labels.activeFiltersMUI}` : `${count} ${labels.activeFilterMUI}`,

                        // Quick filter toolbar field
                        toolbarQuickFilterPlaceholder: labels.toolbarQuickFilterPlaceholderMUI,
                        toolbarQuickFilterLabel: labels.toolbarQuickFilterLabelMUI,
                        toolbarQuickFilterDeleteIconLabel: labels.toolbarQuickFilterDeleteIconLabelMUI,

                        // Export selector toolbar button text
                        toolbarExport: labels.toolbarExportMUI,
                        toolbarExportLabel: labels.toolbarExportLabelMUI,
                        toolbarExportCSV: labels.toolbarExportCSVMUI,
                        toolbarExportPrint: labels.toolbarExportPrintMUI,
                        toolbarExportExcel: labels.toolbarExportExcelMUI,

                        // Columns panel text
                        columnsPanelTextFieldLabel: labels.columnsPanelTextFieldLabelMUI,
                        columnsPanelTextFieldPlaceholder: labels.columnsPanelTextFieldPlaceholderMUI,
                        columnsPanelDragIconLabel: labels.columnsPanelDragIconLabelMUI,
                        columnsPanelShowAllButton: labels.columnsPanelShowAllButtonMUI,
                        columnsPanelHideAllButton: labels.columnsPanelHideAllButtonMUI,

                        // Filter panel text
                        filterPanelAddFilter: labels.filterPanelAddFilterMUI,
                        filterPanelDeleteIconLabel: labels.filterPanelDeleteIconLabelMUI,
                        filterPanelLinkOperator: labels.filterPanelLinkOperatorMUI,
                        filterPanelOperators: labels.filterPanelOperatorsMUI, // TODO v6: rename to filterPanelOperator
                        filterPanelOperatorAnd: labels.filterPanelOperatorAndMUI,
                        filterPanelOperatorOr: labels.filterPanelOperatorOrMUI,
                        filterPanelColumns: labels.filterPanelColumnsMUI,
                        filterPanelInputLabel: labels.filterPanelInputLabelMUI,
                        filterPanelInputPlaceholder: labels.filterPanelInputPlaceholderMUI,

                        // Filter operators text
                        filterOperatorContains: labels.filterOperatorContainsMUI,
                        filterOperatorEquals: labels.filterOperatorEqualsMUI,
                        filterOperatorStartsWith: labels.filterOperatorStartsWithMUI,
                        filterOperatorEndsWith: labels.filterOperatorEndsWithMUI,
                        filterOperatorIs: labels.filterOperatorIsMUI,
                        filterOperatorNot: labels.filterOperatorNotMUI,
                        filterOperatorAfter: labels.filterOperatorAfterMUI,
                        filterOperatorOnOrAfter: labels.filterOperatorOnOrAfterMUI,
                        filterOperatorBefore: labels.filterOperatorBeforeMUI,
                        filterOperatorOnOrBefore: labels.filterOperatorOnOrBeforeMUI,
                        filterOperatorIsEmpty: labels.filterOperatorIsEmptyMUI,
                        filterOperatorIsNotEmpty: labels.filterOperatorIsNotEmptyMUI,
                        filterOperatorIsAnyOf: labels.filterOperatorIsAnyOfMUI,

                        // Filter values text
                        filterValueAny: labels.filterValueAnyMUI,
                        filterValueTrue: labels.filterValueTrueMUI,
                        filterValueFalse: labels.filterValueFalseMUI,

                        // Column menu text
                        columnMenuLabel: labels.columnMenuLabelMUI,
                        columnMenuShowColumns: labels.columnMenuShowColumnsMUI,
                        columnMenuFilter: labels.columnMenuFilterMUI,
                        columnMenuHideColumn: labels.columnMenuHideColumnMUI,
                        columnMenuUnsort: labels.columnMenuUnsortMUI,
                        columnMenuSortAsc: labels.columnMenuSortAscMUI,
                        columnMenuSortDesc: labels.columnMenuSortDescMUI,

                        // Column header text
                        columnHeaderFiltersTooltipActive: (count) =>
                            count !== 1 ? `${count} ${labels.activeFiltersMUI}` : `${count} ${labels.activeFilterMUI}`,
                        columnHeaderFiltersLabel: labels.columnHeaderFiltersLabelMUI,
                        columnHeaderSortIconLabel: labels.columnHeaderSortIconLabelMUI,

                        // Rows selected footer text
                        footerRowSelected: (count) =>
                            count !== 1
                                ? `${count.toLocaleString()} ${labels.rowsSelectedMUI}`
                                : `${count.toLocaleString()} ${labels.rowSelectedMUI}`,

                        // Total row amount footer text
                        footerTotalRows: labels.footerTotalRowsMUI,

                        // Total visible row amount footer text
                        footerTotalVisibleRows: (visibleCount, totalCount) =>
                            `${visibleCount.toLocaleString()} ${labels.ofMUI} ${totalCount.toLocaleString()}`,

                        // Checkbox selection text
                        checkboxSelectionHeaderName: labels.checkboxSelectionHeaderNameMUI,
                        checkboxSelectionSelectAllRows: labels.checkboxSelectionSelectAllRowsMUI,
                        checkboxSelectionUnselectAllRows: labels.checkboxSelectionUnselectAllRowsMUI,
                        checkboxSelectionSelectRow: labels.checkboxSelectionSelectRowMUI,
                        checkboxSelectionUnselectRow: labels.checkboxSelectionUnselectRowMUI,

                        // Boolean cell text
                        booleanCellTrueLabel: labels.booleanCellTrueLabelMUI,
                        booleanCellFalseLabel: labels.booleanCellFalseLabelMUI,

                        // Actions cell more text
                        actionsCellMore: labels.actionsCellMoreMUI,

                        // Column pinning text
                        pinToLeft: labels.pinToLeftMUI,
                        pinToRight: labels.pinToRightMUI,
                        unpin: labels.unpinMUI,

                        // Tree Data
                        treeDataGroupingHeaderName: labels.treeDataGroupingHeaderNameMUI,
                        treeDataExpand: labels.treeDataExpandMUI,
                        treeDataCollapse: labels.treeDataCollapseMUI,

                        // Grouping columns
                        groupingColumnHeaderName: labels.groupingColumnHeaderNameMUI,
                        groupColumn: (name) => `${labels.groupByMUI} ${name}`,
                        unGroupColumn: (name) => `${labels.stopGroupByMUI} ${name}`,

                        // Master/detail
                        detailPanelToggle: labels.detailPanelToggleMUI,
                        expandDetailPanel: labels.expandDetailPanelMUI,
                        collapseDetailPanel: labels.collapseDetailPanelMUI,

                        // Used core components translation keys
                        MuiTablePagination: {},

                        // Row reordering text
                        rowReorderingHeaderName: labels.rowReorderingHeaderNameMUI,

                        // Aggregation
                        aggregationMenuItemHeader: labels.aggregationMenuItemHeaderMUI,
                        aggregationFunctionLabelSum: labels.aggregationFunctionLabelSumMUI,
                        aggregationFunctionLabelAvg: labels.aggregationFunctionLabelAvgMUI,
                        aggregationFunctionLabelMin: labels.aggregationFunctionLabelMinMUI,
                        aggregationFunctionLabelMax: labels.aggregationFunctionLabelMaxMUI,
                        aggregationFunctionLabelSize: labels.aggregationFunctionLabelSizeMUI
                    }}
                    slots={{ toolbar: props.insetToolbar }}
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: labels.labelRowsPerPageMUI,
                            labelDisplayedRows: (p) => {
                                return p.from + " " + labels.rowsOfMUI + " " + p.to;
                            }
                        }
                    }}
                    rows={props.rows}
                    columns={props.columns}
                    initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
                    pageSizeOptions={[50, 100]}
                    sx={{ border: 0 }}
                />
                : <DataGrid                    
                    slots={{ toolbar: props.insetToolbar }}
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: labels.labelRowsPerPageMUI,
                            labelDisplayedRows: (p) => {
                                return p.from + " " + labels.rowsOfMUI + " " + p.to;
                            }
                        }
                    }}
                    rows={props.rows}
                    columns={props.columns}
                    initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
                    pageSizeOptions={[50, 100]}
                    sx={{ border: 0 }}
                />
            }
        </>
    );
}

export default TranslatableDataGrid;