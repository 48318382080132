import React from 'react';
import PricingHelper from '../helpers/PricingHelper';

const Money = (props) => {
    return (
        <React.Fragment>
            {props.currency && props.country && (
                <span className="money">
                    {PricingHelper.formatValue(props.country, props.currency, props.value)}
                </span>
            )}
        </React.Fragment>
    );
};

export default Money;