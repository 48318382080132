import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Strings from './ProductSearchResults.Strings';
import Loading from '../Loading';
import Api from '../../services/Api';
import MessageStandard from '../MessageStandard';
import MessageWarning from '../MessageWarning';
import Pager from '../Pager';
import { withRouter } from 'react-router-dom';
import ProductSearchResultItem from './ProductSearchResultItem';
import * as cartActions from '../../actions/cartActions';
import Localization from '../../services/Localization';
import UserHelper from '../../helpers/UserHelper';
import { withSelectedCustomer } from '../../hooks/withSelectedCustomer';
import { withProductConfig } from '../../hooks/ProductConfigContext';

class ProductSearchResults extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            sku: "",
            error: false,
            products: null,
            pageSize: 10,
            page: 1,
            totalPages: 1,
            redraw: 0,
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.setSku(this.props);
        this.updatePriceAvailability();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setSku(this.props);
            this.updatePriceAvailability();
        }
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    setSku = (props) => {
        if (props && props.sku)
            this.setMountedState({ sku: props.sku, page: props.page }, this.fetchResults)
    }

    fetchResults = () => {
        this.startFetching();
        let brandCode = this.props.selectedCustomer.brandCode;
        let companyCode = this.props.selectedCustomer.companyCode;
        let customerId = this.props.selectedCustomer.id;
        let storefrontId = this.props.currentUser.storefrontId;
        let url = `api/products/search/${this.state.sku}/${customerId}/${companyCode}/${storefrontId}`;
        if (brandCode) {
            url = `${url}/${brandCode}`;
        }
        url = `${url}?locale=${Localization.language}`
        Api.fetch(`${url}&pageNum=${this.state.page}&pageSize=${this.state.pageSize}`)
            .then(this.handleSearchResponse)
            .catch(this.handleError);
    }

    startFetching = () => {
        this.setMountedState({ fetching: true, products: null })
    }

    stopFetching = () => {
        this.setMountedState({ fetching: false });
    }

    handleAddItemToCart = (product, localized) => {
        this.props.cartActions.AddItemToCart(product, localized, () => this.setMountedState({ redraw: this.state.redraw + 1 }));
    }

    handleSearchResponse = (response) => {
        if (response && response.products) {
            const totalCount = response.totalCount;
            if (totalCount === 1) {
                const productDetailUrl = "/product/" + response.products[0].sku;
                this.props.history.push({ pathname: productDetailUrl, state: { selectedCustomer: this.props.selectedCustomer } });
            }
            else {
                const totalPages = Math.ceil(totalCount / this.state.pageSize);
                const currentPage = this.state.page > totalPages ? totalPages : this.state.page;
                this.setMountedState({ products: response.products, totalPages: totalPages, page: currentPage }, this.stopFetching);
            }
        }
    }

    handleError = () => {
        this.setMountedState({ error: true }, this.stopFetching);
    }

    exactSkuExistsInProducts() {
        return this.state.products.filter(p => p.sku === this.state.sku).length > 0;
    }

    exactMatchingProduct = () => {
        return this.state.products.filter(p => p.sku === this.state.sku)[0]
    }

    updatePriceAvailability = () => {
        if (this.props.selectedCustomer)
            this.checkPriceAvailaibility = UserHelper.checkPriceAvailability(this.props.selectedCustomer, this.props.currentUser.storefrontNumber);
    }
    render() {
        const { fetching, sku, error, products } = this.state;

        if (sku.length === 0)
            return null;
        const localized = this.props.productConfig.labels;
        const customerWarningMessage = (this.props.selectedCustomer.isActive === false) ? localized.InActiveCustomerSelectedWarningMessage : localized.ShipToNotSelectedWarningMessage;
        return (
            <div className="product-search-results">

                {
                    this.props.selectedCustomer &&
                    !this.checkPriceAvailaibility &&
                    <MessageWarning title={localized.ShipToNotSelectedWarningTitle} message={customerWarningMessage} />
                }
                {
                    fetching &&
                    <Loading type="brand" />
                }

                {
                    !fetching && error &&
                    <div className="error">
                        <MessageStandard line1={localized.ErrorRetrievingItemsMessage} />
                    </div>
                }
                {
                    !fetching && products && products.length === 0 &&
                    <div className="no-product-found">
                        <MessageStandard line1={localized.NoItemMessage} />
                    </div>
                }
                {
                    !fetching &&
                    products &&
                    products.length > 0 &&
                    <div className="fele-product-listing">
                        <div className="heading2">{localized.SearchResults}</div>                       
                            <div className="product-listing-header">
                                <div className="product-image" style={{ 'text-align': "left", 'min-width': '8%' }}>
                                    <div className="label">&nbsp;</div>
                                </div>

                                <div className="sku" style={{ 'text-align': "left", 'min-width': '15%' }}>
                                    <div className="label">{localized.Item}</div>
                                </div>

                                <div className="part-name" style={{ 'text-align': "left", 'min-width': '37%' }}>
                                    <div className="label">{localized.Description_Label}</div>
                                </div>

                                {
                                    !!this.props.productConfig.settings.HideListPrice &&
                                    <div className="list-price" style={{ 'text-align': "left", 'min-width': '10%' }}>
                                        <div className="label">{localized.ListPriceLabel}</div>
                                    </div>
                                }

                                <div className="cost" style={{ 'text-align': "left", 'min-width': '20%' }}>
                                    <div className="label">{localized.AvailabilityCost_Label}</div>
                                </div>

                                <div className="add-to-cart" style={{ 'text-align': "center", 'min-width': '10%' }}>
                                    <div className="label">{localized.Quantity}</div>
                                </div>
                        </div>

                        {
                            this.state.products.map((product, key) =>
                                <ProductSearchResultItem
                                    product={product} key={key + (this.state.redraw || 0)}
                                    currentUser={this.props.currentUser}
                                    selectedCustomer={this.props.selectedCustomer}
                                    hideListPrice={this.props.productConfig.settings.HideListPrice}
                                    checkPriceAvailability={this.checkPriceAvailaibility}
                                    AddItemToCart={this.handleAddItemToCart}
                                    hideYourCost={this.props.hideYourCost}
                                    cartLines={this.props.items}
                                    redraw={this.state.redraw}
                                />
                            )
                        }
                    </div>
                }
                <div>
                    <Pager activePage={this.state.page} count={this.state.totalPages} selectedCustomer={this.props.selectedCustomer} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.user.currentUser,
        items: state.cart.items,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        cartActions: bindActionCreators(cartActions, dispatch),
    };
}

export default compose(
    withRouter,
    connect(mapStateToProps,
        mapDispatchToProps)
)(withProductConfig(withSelectedCustomer(ProductSearchResults)));