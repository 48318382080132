import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import OrdersFilter from './orders/OrdersFilter';
import OrderLines from './orders/OrderLines';
import Orders from './orders/Orders';
import QuotesFilter from './quotes/QuotesFilter';
import Quotes from './quotes/Quotes';
import Paging from './Paging';
import Loading from './Loading';
import StatusHelper from '../helpers/StatusHelper';
import DateHelper from '../helpers/DateHelper';
import Notifications from '../services/Notifications';
import { useStateStorageConfig } from '../hooks/StateStorageConfigContext';
import OrderService from '../services/OrderService';
import { withSelectedCustomer } from '../hooks/withSelectedCustomer';
import { withSalesHistoryConfig } from '../hooks/SalesHistoryConfigContext';
import { withStorefrontConfig } from '../hooks/StorefrontSettingsContext';
import NotificationBanner from "./banner/NotificationBanner";

const getStringDate = (date) => {
    if (!date) return '';
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
        return '';
    } else {
        const year = dateObj.getFullYear().toString();
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const day = dateObj.getDate().toString();
        return `${year}-${month}-${day}`;
    }
};
export const OrderHistory = (props) => {
    const ShowOrderHistoryEstimatedShipDate = props.salesHistoryConfig.settings.ShowOrderHistoryEstimatedShipDate;

    const activeOrderRange = props.location.query ? props.location.query.dateRange : 30;
    const activeOrderStatuses = props.location.query ? props.location.query.statuses : [];
    const [dateRange, setDateRange] = useState(activeOrderRange);
    const [status, setStatus] = useState(activeOrderStatuses);
    const [number, setNumber] = useState("");
    const [sort, setSort] = useState("0");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [hideQuoteFilters, setHideQuoteFilters] = useState(false);
    const [orders, setOrders] = useState(null);
    const [count, setCount] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [customerId, setCustomerId] = useState(null);
    const [fullOrdersList, setFullOrdersList] = useState(null);
    const [fromDate, setFromDate] = useState(getStringDate(new Date().setDate(new Date().getDate() - activeOrderRange)));
    const [fromFullDate, setFromFullDate] = useState(new Date().setDate(new Date().getDate() - activeOrderRange));
    const [toFullDate, setToFullDate] = useState(new Date());
    const [toDate, setToDate] = useState(getStringDate(new Date()));

    //Quotes
    const [quoteStatusFilterOptions, setQuoteStatusFilterOptions] = useState(null);
    const stateStorageConfig = useStateStorageConfig();
    const localized = props.salesHistoryConfig.labels;
    const [resetFilterHit, SetResetFilterHit] = useState(false);
    const [loadfromState, SetLoadfromState] = useState(false);

    useEffect(() => {
        const custId = props && props.selectedCustomer ? props.selectedCustomer.id : null;
        if (!custId) {
            props.history.push({ pathname: '/' });
        }
    }, [customerId]);

    useEffect(() => {
        if (props && props.selectedCustomer && customerId !== props.selectedCustomer.id) {
            setCustomerId(props.selectedCustomer.id);
            updateOrders();
        }
        setPageSize(100);
    }, [props.selectedCustomer]);

    const updateStateHistoryFilters = (data) => {
        stateStorageConfig.setHistoryFilters(data);
    };

    useEffect(() => {
        if (!fetching) {
            updateOrders();
        }
    }, [pageSize, status, fromDate, toDate, number]);

    useEffect(() => {

        if (!fetching && loadfromState) {0
.            updateOrders();

            SetLoadfromState(false);
        }
    }, [loadfromState, fetching]);

    //Order Screen filters functions
    const updateFilterStatus = (e) => {
        let status = [];
        if (props.salesHistoryConfig.settings.OrdersByLine == true || props.salesHistoryConfig.settings.ShowOrderStatus == true) {
            e.forEach((x) => {
                status.push(x.value)
            });
        } else {
            status = [];
        }
        updateStateHistoryFilters({ status: status });
        setStatus(status);
        setPage(1);
    };

    const updateFilterSortQuotes = (e) => {
        setSort(e.value);
        setPage(1);
    };

    const updateFilterStatusQuotes = (e) => {
        let status = [];
        if (props.salesHistoryConfig.settings.OrdersByLine == true) {
            e.forEach((x) => {
                status.push(x.value)
            });
        } else {
            status = [];
        }
        updateStateHistoryFilters({ status: status });
        setStatus(status);
        setPage(1);

    };

    const updateFilterFromDate = (e) => {
        const currentDate = new Date();
        const toDate = toFullDate;
        // From Date can't be in the future
        var futureFromDateResult = DateHelper.compareDates(e, currentDate);
        // From Date must be less than to date
        var result = DateHelper.compareDates(e, toDate);
        if (futureFromDateResult == 1) {
            return Notifications.error(localized.FromDateFutureError);
        } else if (result == 1) {
            return Notifications.error(localized.FromDateToDateError);
        } else {
            updateStateHistoryFilters({ fromDate: getStringDate(e) });
            updateStateHistoryFilters({ fromFullDate: e });
            setFromFullDate(e);
            setFromDate(getStringDate(e)); // passing only date no time due to timezone
            setPage(1);
        }
    };

    const updateFilterToDate = (e) => {
        // To Date must be greater than from date
        const parseToDate = Date.parse(e);
        const parseFromDate = Date.parse(fromDate);
        if (parseToDate < parseFromDate) {
            return Notifications.error(localized.ToDateError);
        }

        updateStateHistoryFilters({ toDate: getStringDate(e) });
        updateStateHistoryFilters({ toFullDate: e });
        setToFullDate(e);
        setToDate(getStringDate(e)); // passing only date no time due to timezone
        setPage(1);
    };

    const updateFilterSearchNumber = (e) => {
        updateStateHistoryFilters({ number: e.target.value });
        setNumber(e.target.value);
        setPage(1);
    };

    const resetAllStateFilters = (e) => {
        stateStorageConfig.resetHistoryFilters();
        const date = new Date().setDate(new Date().getDate() - activeOrderRange);
        setNumber('');
        setStatus([]);
        setFromDate(getStringDate(new Date(date)));
        setFromFullDate(new Date(date));
        setToFullDate(new Date());
        setToDate(getStringDate(new Date()));
        setPage(1);
    };

    //order history and its functions ended

    /*Quote History Functions */
    useEffect(() => {
        if (!fetching) {
            updateOrders();
        }
    }, [sort]);

    useEffect(() => {
        if (orders && orders.length > 0 && props.isQuote && !fetching) {
            loadedOrders(orders);
        }
    }, [orders]);

    //This function is use to load Status filter in Quote filters
    const loadedOrders = (data) => {
        if (data) {
            var statusResult = [];
            const key = props.isQuote ? 'quoteStatus' : 'status';
            const filterStatus = [...new Map(data?.map(item => [item[key], item])).values()];
            filterStatus.forEach((o) => {
                statusResult.push({ value: o[key], label: StatusHelper.formatQuoteStatus(o[key], localized) });
            });
            setQuoteStatusFilterOptions(statusResult);
        }
    };



    const updateFilterFromDateQuote = (e) => {
        const currentDate = new Date();
        const toDate = toFullDate;
        // From Date can't be in the future
        var futureFromDateResult = DateHelper.compareDates(e, currentDate);
        // From Date must be less than to date
        var result = DateHelper.compareDates(e, toDate);
        if (futureFromDateResult == 1) {
            return Notifications.error(localized.FromDateFutureError);
        } else if (result == 1) {
            return Notifications.error(localized.FromDateToDateError);
        } else {
            updateStateHistoryFilters({ fromDate: getStringDate(e) });
            updateStateHistoryFilters({ fromFullDate: e });
            setFromFullDate(e);
            setFromDate(getStringDate(e)); // passing only date no time due to timezone
            setPage(1);
        }
    };

    const showHideQuotesFilter = (hide) => {
        setHideQuoteFilters(hide);
    };

    const updateOrders = () => {
        fetchOrders();
        getFullOrdersList();
    };

    const pageCount = () => {
        return Math.ceil(count / pageSize);
    };

    const nextPage = () => {
        if (page < pageCount()) {
            setPage(page + 1);
            fetchOrders();
        }
    };
    const previousPage = () => {
        if (page > 1) {
            setPage(page - 1);
            fetchOrders();
        }
    };

    const updateIsFetching = (newFetching) => {
        setFetching(newFetching);
    };

    const fetchOrders = () => {
        if (props.selectedCustomer) {
            setFetching(true);
            let state = {
                number,
                pageSize,
                page,
                dateRange,
                sort,
                fromDate,
                toDate,
                status
            };

            return OrderService.fetchOrders(state, props, false)
                .then((data) => {
                    if (data) {
                        setFetching(false);
                        setOrders(data.orders);
                        setCount(data.totalCount);
                    }
                });
        }
    };

    const getFullOrdersList = () => {
        setFetching(true);
        let state = {
            number,
            pageSize,
            page,
            dateRange,
            sort,
            fromDate,
            toDate,
            status
        };
       // console.log("getFullOrdersList", state);

        if (!props.isQuote) {
            return OrderService.fetchOrders(state, props, true)
                .then((data) => {
                    if (data) {
                        setFullOrdersList(data.orders);
                        setFetching(false);
                    }
                });
        }
    };

    const renderOrderFilter = () => {
        return (
            <OrdersFilter
                setStatus={updateFilterStatus}
                setFromDate={updateFilterFromDate}
                setToDate={updateFilterToDate}
                setNumber={updateFilterSearchNumber}
                status={status}
                fetchOrders={updateOrders}
                resetFilters={resetAllStateFilters}

                fromFullDate={fromFullDate}
                toFullDate={toFullDate}
                statusFilter={status}
                numberFilter={number}

                historyByLine={props.salesHistoryConfig.settings.OrdersByLine === true}

                localized={props.salesHistoryConfig.labels}
                dateFormat={props.StorefrontConfig.settingsList.DateFormat}
                availableStatuses={props.StorefrontConfig.storefrontOrderQuoteStatuses}

            />
        );
    };

    const renderOrdersByLine = () => {

        return (
            <div>
                {renderOrderFilter()}
                <OrderLines
                    selectedCustomer={props.selectedCustomer}
                    currentUser={props.currentUser}
                    orderLines={orders}
                    fetching={fetching}
                    renderPaging={renderPaging}
                    ordersByLinePDF={props.salesHistoryConfig.settings.OrdersByLinePDF === true}
                    shareViaWhatsApp={props.salesHistoryConfig.settings.ShareViaWhatsApp === true}
                    csvOrders={fullOrdersList}
                    localized={props.salesHistoryConfig.labels}
                    dateDisplayFormat={props.StorefrontConfig.settingsList.DateDisplayFormat}
                    showEstimatedShipDate={ShowOrderHistoryEstimatedShipDate}
                />
            </div>
        );
    };

    const renderOrderHeaders = () => {
        return (
            <div>
                {renderOrderFilter()}
                <Orders
                    orders={orders}
                    fetching={fetching}
                    renderPaging={renderPaging}
                    localized={props.salesHistoryConfig.labels}
                />
            </div>
        );
    };

    const renderQuotesHeaders = () => {
        return (
            <div>
                {!hideQuoteFilters &&
                    <div id="quotes-filter-container">
                        <QuotesFilter
                            setSort={updateFilterSortQuotes}
                            sort={sort}
                            quoteStatus={status }
                            quoteStatusFilter={quoteStatusFilterOptions}
                            setStatus={updateFilterStatusQuotes}

                            setFromDate={updateFilterFromDateQuote}
                            setToDate={updateFilterToDate}
                            fromFullDate={fromFullDate}
                            toFullDate={toFullDate}
                            maxFromDate={props.StorefrontConfig.settingsList.QuoteHistoryMax}
                            numberFilter={number}

                            setNumber={updateFilterSearchNumber}
                            fetchOrders={fetchOrders}
                            localized={props.salesHistoryConfig.labels}
                            availableStatuses={props.StorefrontConfig.storefrontOrderQuoteStatuses}
                            dateFormat={props.StorefrontConfig.settingsList.DateFormat}
                        />
                    </div>
                }
                <Quotes
                    selectedCustomer={props.selectedCustomer}
                    currentUser={props.currentUser}
                    quotes={orders}
                    fetching={fetching}
                    hideQuotesFilter={showHideQuotesFilter}
                    renderPaging={renderPaging}
                    downloadQuotePDF={props.salesHistoryConfig.settings.DownloadQuotePDF === true}
                    editQuoteEnabled={props.salesHistoryConfig.settings.EditQuoteEnabled === true}
                    convertQuoteEnabled={props.salesHistoryConfig.settings.ConvertQuoteEnabled === true}
                    rejectQuoteEnabled={props.salesHistoryConfig.settings.RejectQuoteEnabled === true}
                    fetchOrders={fetchOrders}
                    setFetching={updateIsFetching}
                    localized={props.salesHistoryConfig.labels}
                />
            </div>
        );
    };

    const renderPaging = () => {
        return (
            <Paging activePage={page} pageCount={pageCount} nextPage={nextPage} previousPage={previousPage} />
        );
    };

    const ordersByLine = (props.salesHistoryConfig.settings.OrdersByLine === true);
    const { isQuote, selectedCustomer } = props;

    return (
        <React.Fragment>
            {!props.salesHistoryConfig.salesHistoryConfigLoaded &&
                <Loading type="brand" />
            }
            {selectedCustomer && props.salesHistoryConfig.salesHistoryConfigLoaded &&
                <React.Fragment>
                    <NotificationBanner
                        classOverride="mb-4"
                        locale={props.StorefrontConfig.locale}
                        page={props.isQuote ? "QuoteHistory" : "OrderHistory"}
                    />

                    {isQuote &&
                        renderQuotesHeaders()
                    }
                    {!isQuote && ordersByLine &&
                        renderOrdersByLine()
                    }
                    {!isQuote && !ordersByLine &&
                        renderOrderHeaders()
                    }
                </React.Fragment>
            }
        </React.Fragment>
    );
};

OrderHistory.propTypes = {
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
};

function mapStateToProps(state) {
    // console.log("state", state);
    return {
        selectedCustomer: state.user.selectedCustomer,
        currentUser: state.user.currentUser

    };
}

export default connect(
    mapStateToProps, null

)(withRouter(withSalesHistoryConfig(withStorefrontConfig(withSelectedCustomer(OrderHistory)))));

//export default compose(
//    connect(mapStateToProps,
//        null)
//)(withRouter(withSalesHistoryConfig(withStorefrontConfig(withSelectedCustomer(OrderHistory)))));